/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  Json: { input: any; output: any; }
};

export type AddMessageToDealConversationInput = {
  readonly dealId?: InputMaybe<Scalars['String']['input']>;
  readonly message: Scalars['String']['input'];
};

export type AddOrDeleteSavedAdvertInput = {
  readonly advertId: Scalars['String']['input'];
  readonly isSaved?: InputMaybe<Scalars['Boolean']['input']>;
  readonly searchReferrerParams?: InputMaybe<SavedAdvertSearchReferrerParams>;
};

export type AddressInput = {
  readonly buildingName?: InputMaybe<Scalars['String']['input']>;
  readonly buildingNumber?: InputMaybe<Scalars['String']['input']>;
  readonly county: Scalars['String']['input'];
  readonly postcode: Scalars['String']['input'];
  readonly street: Scalars['String']['input'];
  readonly subBuildingName?: InputMaybe<Scalars['String']['input']>;
  readonly town: Scalars['String']['input'];
};

export enum AdvertReservationEligibility {
  RESERVED_ADVERTISER = 'RESERVED_ADVERTISER',
  RESERVED_AUTO_TRADER = 'RESERVED_AUTO_TRADER'
}

export enum AdvertReservationStatus {
  RESERVED_ADVERTISER = 'RESERVED_ADVERTISER',
  RESERVED_AUTO_TRADER = 'RESERVED_AUTO_TRADER'
}

export type AdvertiserInput = {
  readonly advertiserType: Scalars['String']['input'];
  readonly emailAddress: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
  readonly telephone: Scalars['String']['input'];
  readonly ursDbId: Scalars['String']['input'];
};

export enum AdvertisingLocation {
  at_bikes = 'at_bikes',
  at_caravans = 'at_caravans',
  at_cars = 'at_cars',
  at_farm = 'at_farm',
  at_lease_new_car = 'at_lease_new_car',
  at_lease_virtual_car = 'at_lease_virtual_car',
  at_motorhomes = 'at_motorhomes',
  at_new_cars = 'at_new_cars',
  at_new_cars_migration = 'at_new_cars_migration',
  at_plants = 'at_plants',
  at_profile_bikes = 'at_profile_bikes',
  at_profile_caravans = 'at_profile_caravans',
  at_profile_cars = 'at_profile_cars',
  at_profile_farm = 'at_profile_farm',
  at_profile_motorhomes = 'at_profile_motorhomes',
  at_profile_plants = 'at_profile_plants',
  at_profile_trucks = 'at_profile_trucks',
  at_profile_vans = 'at_profile_vans',
  at_trucks = 'at_trucks',
  at_vans = 'at_vans'
}

export enum Alert {
  OFF = 'OFF',
  ON = 'ON'
}

export type ApplicantInput = {
  readonly dateOfBirth: Scalars['String']['input'];
  readonly email: Scalars['String']['input'];
  readonly firstName: Scalars['String']['input'];
  readonly mobile: Scalars['String']['input'];
  readonly surname: Scalars['String']['input'];
  readonly title: TitleInput;
};

export enum ApprovalLikelihoodBucket {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  VERY_HIGH = 'VERY_HIGH'
}

export enum ArticleFacetType {
  make = 'make',
  model = 'model'
}

export type ArticleQuery = {
  readonly author?: InputMaybe<Scalars['String']['input']>;
  readonly bodyType?: InputMaybe<Scalars['String']['input']>;
  readonly collectionId?: InputMaybe<Scalars['String']['input']>;
  readonly cursor?: InputMaybe<Scalars['String']['input']>;
  readonly generationId?: InputMaybe<Scalars['String']['input']>;
  readonly keywordId?: InputMaybe<Scalars['String']['input']>;
  readonly limit?: InputMaybe<Scalars['Int']['input']>;
  readonly make?: InputMaybe<Scalars['String']['input']>;
  readonly model?: InputMaybe<Scalars['String']['input']>;
  readonly seoId?: InputMaybe<Scalars['String']['input']>;
  readonly status?: InputMaybe<ArticleStatus>;
  readonly type?: InputMaybe<ReadonlyArray<InputMaybe<ArticleType>>>;
  readonly vehicleType?: InputMaybe<VehicleType>;
  readonly vehicleTypes?: InputMaybe<ReadonlyArray<InputMaybe<VehicleType>>>;
};

export enum ArticleStatus {
  archived = 'archived',
  draft = 'draft',
  published = 'published'
}

export enum ArticleType {
  advice = 'advice',
  bestOf = 'bestOf',
  expertReview = 'expertReview',
  feature = 'feature',
  firstDrive = 'firstDrive',
  guide = 'guide',
  iBoughtOne = 'iBoughtOne',
  longTermReview = 'longTermReview',
  news = 'news',
  securityAlert = 'securityAlert'
}

export type ArticleTypeFilter = {
  readonly articleTypes: ReadonlyArray<ArticleType>;
  readonly exclude: Scalars['Boolean']['input'];
};

export type AuthorInput = {
  readonly avatarId?: InputMaybe<Scalars['String']['input']>;
  readonly currentCar?: InputMaybe<Scalars['String']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly firstCar?: InputMaybe<Scalars['String']['input']>;
  readonly heroImageId?: InputMaybe<Scalars['String']['input']>;
  readonly jobTitle?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly seoName?: InputMaybe<Scalars['String']['input']>;
  readonly shortDescription?: InputMaybe<Scalars['String']['input']>;
  readonly twitter?: InputMaybe<Scalars['String']['input']>;
};

export enum BadgeType {
  /** 'Brand new' for car / van only */
  BRAND_NEW = 'BRAND_NEW',
  /** 'Available <month>' for new cars that are due in within 6 months */
  DUE_AT_SELLER = 'DUE_AT_SELLER',
  /** 'Finance Available' for vehicles with finance info that have not been imported */
  FINANCE = 'FINANCE',
  /** 'In stock' for new cars without a due date */
  IN_STOCK = 'IN_STOCK',
  /** 'Lease deal' for leasing listings */
  LEASING_LISTING = 'LEASING_LISTING',
  /** 'Approved used' for used vehicles that are manufacturer approved */
  MANUFACTURER_APPROVED = 'MANUFACTURER_APPROVED',
  /** 'X,XXX miles' The vehicle mileage */
  MILEAGE = 'MILEAGE',
  /** 'NCC Member Dealership' for caravans and motorhomes only */
  NCC_APPROVED_DEALER = 'NCC_APPROVED_DEALER',
  /** 'Nearly new' for car / van only */
  NEARLY_NEW = 'NEARLY_NEW',
  /** 'Fair price' */
  PI_FAIR = 'PI_FAIR',
  /** 'Good price' */
  PI_GOOD = 'PI_GOOD',
  /** 'Great price' */
  PI_GREAT = 'PI_GREAT',
  /** High price' */
  PI_HIGH = 'PI_HIGH',
  /** 'Lower price' */
  PI_LOW = 'PI_LOW',
  /** 'Private seller' for stock with a seller type of Private */
  PRIVATE_SELLER = 'PRIVATE_SELLER',
  /** 'YYYY' The year the vehicle was registered */
  REGISTERED_YEAR = 'REGISTERED_YEAR',
  /** 'Save XXX' for MRRP discounts */
  SAVING = 'SAVING',
  /** 'Cat <category' for vehicles with a write off category */
  WRITE_OFF_CATEGORY = 'WRITE_OFF_CATEGORY'
}

export enum BasicListingPriceIndicatorRating {
  FAIR = 'FAIR',
  GOOD = 'GOOD',
  GREAT = 'GREAT',
  HIGH = 'HIGH',
  LOW = 'LOW',
  NOANALYSIS = 'NOANALYSIS'
}

export enum CampaignAdvertisingLocation {
  DISCOVERY_MAKE = 'DISCOVERY_MAKE',
  DISCOVERY_MODEL = 'DISCOVERY_MODEL',
  HOME_PAGE = 'HOME_PAGE'
}

export type CancelDealInput = {
  readonly cancellationJourney: CancellationJourney;
  readonly cancellationReason: CancellationReason;
  readonly cancellationReasonExtra?: InputMaybe<Scalars['String']['input']>;
  readonly dealId: Scalars['String']['input'];
};

export enum CancellationJourney {
  CANCEL = 'CANCEL',
  DID_NOT_PURCHASE = 'DID_NOT_PURCHASE'
}

export enum CancellationReason {
  CONDITION = 'CONDITION',
  DIFFERENT_VEHICLE = 'DIFFERENT_VEHICLE',
  NOT_AVAILABLE = 'NOT_AVAILABLE',
  NOT_INTERESTED = 'NOT_INTERESTED',
  OTHER = 'OTHER',
  POOR_CUSTOMER_SERVICE = 'POOR_CUSTOMER_SERVICE',
  UNAFFORDABLE = 'UNAFFORDABLE',
  WENT_ELSEWHERE = 'WENT_ELSEWHERE'
}

export enum CancelledJourney {
  CANCEL = 'CANCEL',
  DID_NOT_PURCHASE = 'DID_NOT_PURCHASE',
  UNKNOWN = 'UNKNOWN'
}

export enum Channel {
  bikes = 'bikes',
  caravans = 'caravans',
  cars = 'cars',
  cycles = 'cycles',
  farm = 'farm',
  motorhomes = 'motorhomes',
  plant = 'plant',
  trucks = 'trucks',
  vans = 'vans'
}

export enum ChargerLocationType {
  HOME = 'HOME',
  PUBLIC = 'PUBLIC'
}

export type CollectionLocationInput = {
  readonly id: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
};

export type CollectionLocationOptions = {
  readonly searchPostcode: Scalars['String']['input'];
};

export type CommitStateData = {
  readonly commitStateValue: CommitStateValueInput;
  readonly holdingFeeAttachmentType?: InputMaybe<HoldingFeeAttachmentType>;
};

export enum CommitStateValue {
  COMMITTED = 'COMMITTED',
  SKIPPED = 'SKIPPED',
  STAGED = 'STAGED',
  UNKNOWN = 'UNKNOWN',
  UNMAPPED = 'UNMAPPED'
}

export enum CommitStateValueInput {
  COMMITTED = 'COMMITTED',
  SKIPPED = 'SKIPPED',
  STAGED = 'STAGED'
}

export type ComplianceInput = {
  readonly softCheckConsent: Scalars['String']['input'];
};

export enum Condition {
  new = 'new',
  used = 'used'
}

export type ConfirmVehicleCheckOrderPaymentInput = {
  readonly advertId?: InputMaybe<Scalars['String']['input']>;
  readonly orderId: Scalars['String']['input'];
  readonly registration?: InputMaybe<Scalars['String']['input']>;
};

export enum ConsumerDealStatus {
  ARCHIVED = 'ARCHIVED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  IN_PROGRESS = 'IN_PROGRESS',
  PURCHASED = 'PURCHASED',
  PURCHASING = 'PURCHASING'
}

export type ConsumerVehicleCheckInput = {
  readonly advertId?: InputMaybe<Scalars['String']['input']>;
  readonly registration?: InputMaybe<Scalars['String']['input']>;
};

export type CreateDealConversationInput = {
  readonly context: MessagingContextInput;
  readonly dealId: Scalars['String']['input'];
  readonly message: Scalars['String']['input'];
};

export type CreateFinanceApplicationWithEstimatesInput = {
  readonly advertId: Scalars['String']['input'];
  readonly dealId: Scalars['String']['input'];
  readonly financeTerms: FinanceApplicationRequestTerms;
  readonly partExchangeId?: InputMaybe<Scalars['String']['input']>;
};

export type CreateFinanceApplicationWithTermsOnlyInput = {
  readonly advertId: Scalars['String']['input'];
  readonly dealId: Scalars['String']['input'];
  readonly financeTerms: FinanceApplicationRequestTerms;
  readonly partExchangeId?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePartExchangeInput = {
  readonly condition: PartExchangeValuationCondition;
  readonly dealId: Scalars['String']['input'];
  readonly modifications?: InputMaybe<Scalars['String']['input']>;
  readonly outstandingFinance?: InputMaybe<PartExchangeOutstandingFinanceInput>;
  readonly quoteId: Scalars['String']['input'];
  readonly retailerId: Scalars['String']['input'];
};

export type CreatePartExchangeQuoteInput = {
  readonly dealId: Scalars['String']['input'];
  readonly mileage: Scalars['Int']['input'];
  readonly registration: Scalars['String']['input'];
  readonly retailerId: Scalars['String']['input'];
};

export type CreateReservationByPaymentIntentInput = {
  readonly compliance: PaymentIntentCompliance;
  readonly dealId: Scalars['String']['input'];
  readonly journey: ReservationJourney;
  readonly publicReference: Scalars['String']['input'];
  readonly retailerMessage: Scalars['String']['input'];
  readonly vehicleChannel: VehicleChannel;
};

export type CreateUserVehicleInput = {
  readonly atDerivativeId?: InputMaybe<Scalars['String']['input']>;
  readonly derivativeName?: InputMaybe<Scalars['String']['input']>;
  readonly firstRegistration?: InputMaybe<Scalars['String']['input']>;
  readonly make?: InputMaybe<Scalars['String']['input']>;
  readonly mileage?: InputMaybe<Scalars['Int']['input']>;
  readonly model?: InputMaybe<Scalars['String']['input']>;
  readonly vrn?: InputMaybe<Scalars['String']['input']>;
};

export type CreateVehicleCheckOrderInput = {
  readonly captchaToken: Scalars['String']['input'];
};

export enum CreatedDealConversationMessageActor {
  AUTOTRADER = 'AUTOTRADER',
  CONSUMER = 'CONSUMER',
  RETAILER = 'RETAILER',
  UNKNOWN = 'UNKNOWN'
}

export enum CreatedDealConversationMessageStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED = 'REJECTED'
}

export enum DayOfWeek {
  Friday = 'Friday',
  Monday = 'Monday',
  Saturday = 'Saturday',
  Sunday = 'Sunday',
  Thursday = 'Thursday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday'
}

export type DealAdvertData = {
  readonly lifecycleState: Scalars['String']['input'];
  readonly pricing: Pricing;
  readonly primaryImageUrl?: InputMaybe<Scalars['String']['input']>;
  readonly reservationStatus?: InputMaybe<AdvertReservationEligibility>;
  readonly searchId: Scalars['String']['input'];
  readonly stockId?: InputMaybe<Scalars['String']['input']>;
  readonly vehicle: DealVehicleData;
  readonly versionNumber?: InputMaybe<Scalars['String']['input']>;
};

export enum DealAdvertMileageUnit {
  KILOMETERS = 'KILOMETERS',
  MILES = 'MILES'
}

export enum DealAdvertTradeLifecycleStatus {
  DELETED = 'DELETED',
  DUE_IN = 'DUE_IN',
  FORECOURT = 'FORECOURT',
  SALE_IN_PROGRESS = 'SALE_IN_PROGRESS',
  SOLD = 'SOLD',
  WASTEBIN = 'WASTEBIN'
}

export enum DealAdvertVehicleCategory {
  Agricultural = 'Agricultural',
  Bike = 'Bike',
  Car = 'Car',
  Caravan = 'Caravan',
  Cycle = 'Cycle',
  Motorhome = 'Motorhome',
  Plant = 'Plant',
  Truck = 'Truck',
  Van = 'Van'
}

export type DealCapabilitiesData = {
  readonly deliveryOptions: DeliveryOptions;
  readonly finance: Scalars['Boolean']['input'];
  readonly reservations: Scalars['Boolean']['input'];
  readonly sellerPromise: Scalars['Boolean']['input'];
};

export type DealConsumerData = {
  readonly isVatRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  readonly optOutTelephone: Scalars['Boolean']['input'];
  readonly tradingName?: InputMaybe<Scalars['String']['input']>;
  readonly type?: InputMaybe<DealConsumerType>;
};

export enum DealConsumerType {
  LIMITED_COMPANY_OR_PARTNERSHIP = 'LIMITED_COMPANY_OR_PARTNERSHIP',
  PRIVATE = 'PRIVATE',
  SOLE_TRADER = 'SOLE_TRADER'
}

export type DealContext = {
  readonly advert: DealAdvertData;
  readonly capabilities: DealCapabilitiesData;
  readonly consumer?: InputMaybe<DealConsumerData>;
  readonly initiatedJourney?: InputMaybe<InitiatedJourney>;
  readonly retailer: DealRetailerData;
};

export type DealConversationInput = {
  readonly conversationId: Scalars['String']['input'];
};

export enum DealConversationMessageActor {
  AUTOTRADER = 'AUTOTRADER',
  CONSUMER = 'CONSUMER',
  RETAILER = 'RETAILER',
  UNKNOWN = 'UNKNOWN'
}

export enum DealConversationMessageApprovalStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED = 'REJECTED'
}

export type DealConversationSummaryInput = {
  readonly conversationId: Scalars['String']['input'];
};

export type DealRetailerData = {
  readonly id: Scalars['String']['input'];
  readonly tradingName?: InputMaybe<Scalars['String']['input']>;
};

export type DealVehicleData = {
  readonly badgeEngineSizeLitres?: InputMaybe<Scalars['Float']['input']>;
  readonly bodyType?: InputMaybe<Scalars['String']['input']>;
  readonly derivative?: InputMaybe<Scalars['String']['input']>;
  readonly derivativeId?: InputMaybe<Scalars['String']['input']>;
  readonly encryptedVin?: InputMaybe<Scalars['String']['input']>;
  readonly engineCapacityCC?: InputMaybe<Scalars['Int']['input']>;
  readonly fuelType?: InputMaybe<Scalars['String']['input']>;
  readonly make?: InputMaybe<Scalars['String']['input']>;
  readonly model?: InputMaybe<Scalars['String']['input']>;
  readonly odometerReadingMiles?: InputMaybe<Scalars['Int']['input']>;
  readonly ownershipCondition?: InputMaybe<Scalars['String']['input']>;
  readonly registration?: InputMaybe<Scalars['String']['input']>;
  readonly transmissionType?: InputMaybe<Scalars['String']['input']>;
  readonly trim?: InputMaybe<Scalars['String']['input']>;
  readonly vehicleType?: InputMaybe<Scalars['String']['input']>;
  readonly yearOfManufacture?: InputMaybe<Scalars['Int']['input']>;
};

export enum DealVehicleType {
  CAR = 'CAR',
  VAN = 'VAN'
}

export type DealsAddressAndEmploymentHistoryInput = {
  readonly addresses: ReadonlyArray<ResidentialAddressInput>;
  readonly advertId: Scalars['String']['input'];
  readonly dealId: Scalars['String']['input'];
  readonly employments?: InputMaybe<ReadonlyArray<EmploymentInput>>;
  readonly journey: Journey;
  readonly reference: Scalars['String']['input'];
};

export type DealsAddressHistoryInput = {
  readonly addresses: ReadonlyArray<ResidentialAddressInput>;
  readonly advertId: Scalars['String']['input'];
  readonly dealId: Scalars['String']['input'];
  readonly journey: Journey;
  readonly reference: Scalars['String']['input'];
};

export enum DealsAdvertCollectionLocationBadgeType {
  LOCATION = 'LOCATION',
  NEAREST = 'NEAREST'
}

export type DealsAffordabilityInput = {
  readonly advertId: Scalars['String']['input'];
  readonly changeInCircumstances?: InputMaybe<Scalars['Boolean']['input']>;
  readonly dealId: Scalars['String']['input'];
  readonly isAffordableLoan?: InputMaybe<Scalars['Boolean']['input']>;
  readonly journey: Journey;
  readonly lenderId?: InputMaybe<Scalars['String']['input']>;
  readonly monthlyChildcare?: InputMaybe<Scalars['Int']['input']>;
  readonly monthlyRentOrMortgage?: InputMaybe<Scalars['Int']['input']>;
  readonly partnership?: InputMaybe<DealsAffordabilityPartnershipInput>;
  readonly reference: Scalars['String']['input'];
  readonly replacingExistingLoan?: InputMaybe<Scalars['Boolean']['input']>;
  readonly shareholding?: InputMaybe<DealsAffordabilityShareholderInput>;
};

export type DealsAffordabilityPartnershipInput = {
  readonly hasPartnership: Scalars['Boolean']['input'];
  readonly partnershipName?: InputMaybe<Scalars['String']['input']>;
  readonly partnershipNumber?: InputMaybe<Scalars['String']['input']>;
};

export type DealsAffordabilityShareholderInput = {
  readonly companyName?: InputMaybe<Scalars['String']['input']>;
  readonly companyNumber?: InputMaybe<Scalars['String']['input']>;
  readonly hasBusinessShareholder: Scalars['Boolean']['input'];
};

export type DealsBankDetailsInput = {
  readonly accountHolderName: Scalars['String']['input'];
  readonly accountNumber: Scalars['String']['input'];
  readonly advertId: Scalars['String']['input'];
  readonly dealId: Scalars['String']['input'];
  readonly journey: Journey;
  readonly monthsAtBank: Scalars['Int']['input'];
  readonly reference: Scalars['String']['input'];
  readonly sortCode: Scalars['String']['input'];
  readonly yearsAtBank: Scalars['Int']['input'];
};

export type DealsDeliveryUpdateCommitStateInput = {
  readonly commitState: CommitStateValueInput;
  readonly dealId: Scalars['String']['input'];
};

export type DealsEmploymentHistoryInput = {
  readonly advertId: Scalars['String']['input'];
  readonly dealId: Scalars['String']['input'];
  readonly employments: ReadonlyArray<EmploymentInput>;
  readonly journey: Journey;
  readonly reference: Scalars['String']['input'];
};

export type DealsFinanceApplicationInput = {
  readonly advertId: Scalars['String']['input'];
  readonly applicant: ApplicantInput;
  readonly compliance: ComplianceInput;
  readonly dealId: Scalars['String']['input'];
  readonly financeTerms: FinanceTermsInput;
  readonly journey: Journey;
  readonly partExchangeId?: InputMaybe<Scalars['String']['input']>;
};

export type DealsFinanceApplicationQuotesInput = {
  readonly advertId: Scalars['String']['input'];
  readonly dealId: Scalars['String']['input'];
  readonly declinePanelVisibility?: InputMaybe<DeclinePanelVisibility>;
  readonly journey: Journey;
  readonly metadata: FinanceApplicationLeadMetadata;
  readonly reference: Scalars['String']['input'];
};

export type DealsFinanceApplicationUpdatedCommitInput = {
  readonly commitStateValue: CommitStateValueInput;
  readonly dealId: Scalars['String']['input'];
};

export type DealsFinanceUpdateSoftCheckInput = {
  readonly advertId: Scalars['String']['input'];
  readonly dealId: Scalars['String']['input'];
  readonly journey: Journey;
  readonly reference: Scalars['String']['input'];
};

export type DealsSubmitFinanceApplicationInput = {
  readonly advertId: Scalars['String']['input'];
  readonly captchaToken: Scalars['String']['input'];
  readonly dealId: Scalars['String']['input'];
  readonly journey: Journey;
  readonly lenderId: Scalars['String']['input'];
  readonly panelId: Scalars['String']['input'];
  readonly reference: Scalars['String']['input'];
  readonly tierId: Scalars['String']['input'];
};

export type DealsUpdateFinanceApplicationInput = {
  readonly advertId: Scalars['String']['input'];
  readonly dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  readonly dealId: Scalars['String']['input'];
  readonly drivingLicenceType?: InputMaybe<DrivingLicenceTypeInput>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly gender?: InputMaybe<GenderInput>;
  readonly journey: Journey;
  readonly maritalStatus?: InputMaybe<MaritalStatusInput>;
  readonly mobile?: InputMaybe<Scalars['String']['input']>;
  readonly nationalityCode?: InputMaybe<NationalityCodeInput>;
  readonly numberOfDependants?: InputMaybe<Scalars['Int']['input']>;
  readonly reference: Scalars['String']['input'];
  readonly surname?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<TitleInput>;
  readonly validUKPassport?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DealsUpdateFinanceApplicationVehicleDataInput = {
  readonly advertId: Scalars['String']['input'];
  readonly dealId: Scalars['String']['input'];
  readonly journey: Journey;
  readonly partExchangeId?: InputMaybe<Scalars['String']['input']>;
  readonly reference: Scalars['String']['input'];
};

export type DealsUpdateFinanceTermsInput = {
  readonly advertId: Scalars['String']['input'];
  readonly dealId: Scalars['String']['input'];
  readonly financeTerms: FinanceTermsInput;
  readonly journey: Journey;
  readonly reference: Scalars['String']['input'];
};

export enum DeclinePanelVisibility {
  NEVER = 'NEVER',
  SHOW_ALWAYS = 'SHOW_ALWAYS',
  SHOW_IF_INELIGIBLE = 'SHOW_IF_INELIGIBLE'
}

export type DeleteAllSavedAdvertsInput = {
  readonly expiredOnly?: InputMaybe<Scalars['Boolean']['input']>;
};

export type DeleteUserVehicleInput = {
  readonly vehicleId: Scalars['String']['input'];
};

export enum DeliveryMethod {
  CLICK_AND_COLLECT = 'CLICK_AND_COLLECT',
  DECIDE_LATER = 'DECIDE_LATER',
  HOME_DELIVERY = 'HOME_DELIVERY',
  UNKNOWN = 'UNKNOWN'
}

export enum DeliveryOption {
  CLICK_AND_COLLECT = 'CLICK_AND_COLLECT',
  DECIDE_LATER = 'DECIDE_LATER',
  HOME_DELIVERY = 'HOME_DELIVERY'
}

export type DeliveryOptions = {
  readonly extendedClickAndCollect: Scalars['Boolean']['input'];
  readonly homeDelivery: Scalars['Boolean']['input'];
  readonly localClickAndCollect: Scalars['Boolean']['input'];
};

export type DiscoveryGenerationInput = {
  readonly bodyTypes?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly maxBatteryRange?: InputMaybe<Scalars['Int']['input']>;
  readonly maxPrice?: InputMaybe<Scalars['Int']['input']>;
  readonly minBatteryRange?: InputMaybe<Scalars['Int']['input']>;
  readonly minPrice?: InputMaybe<Scalars['Int']['input']>;
  readonly seats?: InputMaybe<ReadonlyArray<Scalars['Int']['input']>>;
};

export enum DrivingLicenceTypeInput {
  A = 'A',
  A2 = 'A2',
  CBT = 'CBT',
  EUROPEAN = 'EUROPEAN',
  FULL = 'FULL',
  INTERNATIONAL = 'INTERNATIONAL',
  NONE = 'NONE',
  PROVISIONAL = 'PROVISIONAL'
}

export type EmploymentInput = {
  readonly address?: InputMaybe<AddressInput>;
  readonly employmentStatus: EmploymentStatusInput;
  readonly jobTitle?: InputMaybe<Scalars['String']['input']>;
  readonly monthlyIncome?: InputMaybe<Scalars['Int']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly timeAtEmployer: TimeAtEmployerInput;
};

export enum EmploymentStatusInput {
  ARMED_SERVICES = 'ARMED_SERVICES',
  BENEFITS = 'BENEFITS',
  CARER = 'CARER',
  EDUCATION = 'EDUCATION',
  FULL_TIME = 'FULL_TIME',
  HOMEMAKER = 'HOMEMAKER',
  OTHER = 'OTHER',
  PART_TIME = 'PART_TIME',
  RETIRED = 'RETIRED',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  TEMPORARY_CONTRACT = 'TEMPORARY_CONTRACT'
}

export enum EmploymentStatusResponse {
  ARMED_SERVICES = 'ARMED_SERVICES',
  BENEFITS = 'BENEFITS',
  CARER = 'CARER',
  EDUCATION = 'EDUCATION',
  FULL_TIME = 'FULL_TIME',
  HOMEMAKER = 'HOMEMAKER',
  OTHER = 'OTHER',
  PART_TIME = 'PART_TIME',
  RETIRED = 'RETIRED',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  TEMPORARY_CONTRACT = 'TEMPORARY_CONTRACT'
}

export enum ErrorDetail {
  /**
   * The deadline expired before the operation could complete.
   *
   * For operations that change the state of the system, this error
   * may be returned even if the operation has completed successfully.
   * For example, a successful response from a server could have been
   * delayed long enough for the deadline to expire.
   *
   * HTTP Mapping: 504 Gateway Timeout
   * Error Type: UNAVAILABLE
   */
  DEADLINE_EXCEEDED = 'DEADLINE_EXCEEDED',
  /**
   * The server detected that the client is exhibiting a behavior that
   * might be generating excessive load.
   *
   * HTTP Mapping: 429 Too Many Requests or 420 Enhance Your Calm
   * Error Type: UNAVAILABLE
   */
  ENHANCE_YOUR_CALM = 'ENHANCE_YOUR_CALM',
  /**
   * The requested field is not found in the schema.
   *
   * This differs from `NOT_FOUND` in that `NOT_FOUND` should be used when a
   * query is valid, but is unable to return a result (if, for example, a
   * specific video id doesn't exist). `FIELD_NOT_FOUND` is intended to be
   * returned by the server to signify that the requested field is not known to exist.
   * This may be returned in lieu of failing the entire query.
   * See also `PERMISSION_DENIED` for cases where the
   * requested field is invalid only for the given user or class of users.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: BAD_REQUEST
   */
  FIELD_NOT_FOUND = 'FIELD_NOT_FOUND',
  /**
   * The client specified an invalid argument.
   *
   * Note that this differs from `FAILED_PRECONDITION`.
   * `INVALID_ARGUMENT` indicates arguments that are problematic
   * regardless of the state of the system (e.g., a malformed file name).
   *
   * HTTP Mapping: 400 Bad Request
   * Error Type: BAD_REQUEST
   */
  INVALID_ARGUMENT = 'INVALID_ARGUMENT',
  /**
   * The provided cursor is not valid.
   *
   * The most common usage for this error is when a client is paginating
   * through a list that uses stateful cursors. In that case, the provided
   * cursor may be expired.
   *
   * HTTP Mapping: 404 Not Found
   * Error Type: NOT_FOUND
   */
  INVALID_CURSOR = 'INVALID_CURSOR',
  /**
   * Unable to perform operation because a required resource is missing.
   *
   * Example: Client is attempting to refresh a list, but the specified
   * list is expired. This requires an action by the client to get a new list.
   *
   * If the user is simply trying GET a resource that is not found,
   * use the NOT_FOUND error type. FAILED_PRECONDITION.MISSING_RESOURCE
   * is to be used particularly when the user is performing an operation
   * that requires a particular resource to exist.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   * Error Type: FAILED_PRECONDITION
   */
  MISSING_RESOURCE = 'MISSING_RESOURCE',
  /**
   * Service Error.
   *
   * There is a problem with an upstream service.
   *
   * This may be returned if a gateway receives an unknown error from a service
   * or if a service is unreachable.
   * If a request times out which waiting on a response from a service,
   * `DEADLINE_EXCEEDED` may be returned instead.
   * If a service returns a more specific error Type, the specific error Type may
   * be returned instead.
   *
   * HTTP Mapping: 502 Bad Gateway
   * Error Type: UNAVAILABLE
   */
  SERVICE_ERROR = 'SERVICE_ERROR',
  /**
   * Request failed due to network errors.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  TCP_FAILURE = 'TCP_FAILURE',
  /**
   * Request throttled based on server concurrency limits.
   *
   * HTTP Mapping: 503 Unavailable
   * Error Type: UNAVAILABLE
   */
  THROTTLED_CONCURRENCY = 'THROTTLED_CONCURRENCY',
  /**
   * Request throttled based on server CPU limits
   *
   * HTTP Mapping: 503 Unavailable.
   * Error Type: UNAVAILABLE
   */
  THROTTLED_CPU = 'THROTTLED_CPU',
  /**
   * The operation is not implemented or is not currently supported/enabled.
   *
   * HTTP Mapping: 501 Not Implemented
   * Error Type: BAD_REQUEST
   */
  UNIMPLEMENTED = 'UNIMPLEMENTED',
  /**
   * Unknown error.
   *
   * This error should only be returned when no other error detail applies.
   * If a client sees an unknown errorDetail, it will be interpreted as UNKNOWN.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  UNKNOWN = 'UNKNOWN'
}

export enum ErrorReason {
  COMPLETE_DEAL_ERROR = 'COMPLETE_DEAL_ERROR',
  CREATE_CONVERSATION_ERROR = 'CREATE_CONVERSATION_ERROR',
  RESERVATION_CHECK_ERROR = 'RESERVATION_CHECK_ERROR'
}

export enum ErrorType {
  /**
   * Bad Request.
   *
   * There is a problem with the request.
   * Retrying the same request is not likely to succeed.
   * An example would be a query or argument that cannot be deserialized.
   *
   * HTTP Mapping: 400 Bad Request
   */
  BAD_REQUEST = 'BAD_REQUEST',
  /**
   * The operation was rejected because the system is not in a state
   * required for the operation's execution.  For example, the directory
   * to be deleted is non-empty, an rmdir operation is applied to
   * a non-directory, etc.
   *
   * Service implementers can use the following guidelines to decide
   * between `FAILED_PRECONDITION` and `UNAVAILABLE`:
   *
   * - Use `UNAVAILABLE` if the client can retry just the failing call.
   * - Use `FAILED_PRECONDITION` if the client should not retry until
   * the system state has been explicitly fixed.  E.g., if an "rmdir"
   *      fails because the directory is non-empty, `FAILED_PRECONDITION`
   * should be returned since the client should not retry unless
   * the files are deleted from the directory.
   *
   * HTTP Mapping: 400 Bad Request or 500 Internal Server Error
   */
  FAILED_PRECONDITION = 'FAILED_PRECONDITION',
  /**
   * Internal error.
   *
   * An unexpected internal error was encountered. This means that some
   * invariants expected by the underlying system have been broken.
   * This error code is reserved for serious errors.
   *
   * HTTP Mapping: 500 Internal Server Error
   */
  INTERNAL = 'INTERNAL',
  /**
   * The requested entity was not found.
   *
   * This could apply to a resource that has never existed (e.g. bad resource id),
   * or a resource that no longer exists (e.g. cache expired.)
   *
   * Note to server developers: if a request is denied for an entire class
   * of users, such as gradual feature rollout or undocumented allowlist,
   * `NOT_FOUND` may be used. If a request is denied for some users within
   * a class of users, such as user-based access control, `PERMISSION_DENIED`
   * must be used.
   *
   * HTTP Mapping: 404 Not Found
   */
  NOT_FOUND = 'NOT_FOUND',
  /**
   * The caller does not have permission to execute the specified
   * operation.
   *
   * `PERMISSION_DENIED` must not be used for rejections
   * caused by exhausting some resource or quota.
   * `PERMISSION_DENIED` must not be used if the caller
   * cannot be identified (use `UNAUTHENTICATED`
   * instead for those errors).
   *
   * This error Type does not imply the
   * request is valid or the requested entity exists or satisfies
   * other pre-conditions.
   *
   * HTTP Mapping: 403 Forbidden
   */
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  /**
   * The request does not have valid authentication credentials.
   *
   * This is intended to be returned only for routes that require
   * authentication.
   *
   * HTTP Mapping: 401 Unauthorized
   */
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  /**
   * Currently Unavailable.
   *
   * The service is currently unavailable.  This is most likely a
   * transient condition, which can be corrected by retrying with
   * a backoff.
   *
   * HTTP Mapping: 503 Unavailable
   */
  UNAVAILABLE = 'UNAVAILABLE',
  /**
   * Unknown error.
   *
   * For example, this error may be returned when
   * an error code received from another address space belongs to
   * an error space that is not known in this address space.  Also
   * errors raised by APIs that do not return enough error information
   * may be converted to this error.
   *
   * If a client sees an unknown errorType, it will be interpreted as UNKNOWN.
   * Unknown errors MUST NOT trigger any special behavior. These MAY be treated
   * by an implementation as being equivalent to INTERNAL.
   *
   * When possible, a more specific error should be provided.
   *
   * HTTP Mapping: 520 Unknown Error
   */
  UNKNOWN = 'UNKNOWN'
}

export type ExperianEnquiryInput = {
  readonly applicant: LeasingApplicantInput;
  readonly consent: LeasingConsentInput;
  readonly currentAddress: LeasingAddressInput;
};

export enum FacetGroupName {
  acceleration = 'acceleration',
  axle_config = 'axle_config',
  battery_range = 'battery_range',
  bedroom_layout = 'bedroom_layout',
  berth = 'berth',
  body_type = 'body_type',
  boot_space = 'boot_space',
  cab_type = 'cab_type',
  charging_time = 'charging_time',
  co2_emissions = 'co2_emissions',
  colour = 'colour',
  digital_retailing = 'digital_retailing',
  distance = 'distance',
  doors = 'doors',
  drive_type = 'drive_type',
  driver_position = 'driver_position',
  emission_class = 'emission_class',
  end_layout = 'end_layout',
  engine_power = 'engine_power',
  engine_size = 'engine_size',
  engine_size_cc = 'engine_size_cc',
  exterior_length = 'exterior_length',
  frame_size = 'frame_size',
  fuel_consumption = 'fuel_consumption',
  fuel_type = 'fuel_type',
  gearbox = 'gearbox',
  gross_trailer_weight = 'gross_trailer_weight',
  gross_vehicle_weight = 'gross_vehicle_weight',
  /** @deprecated No longer supported */
  home_charging_time = 'home_charging_time',
  hours_used = 'hours_used',
  insurance_group = 'insurance_group',
  keyword_search = 'keyword_search',
  make_and_model = 'make_and_model',
  mileage = 'mileage',
  monthly_price = 'monthly_price',
  mtplm = 'mtplm',
  previously_written_off = 'previously_written_off',
  price = 'price',
  /** @deprecated No longer supported */
  public_charging_time = 'public_charging_time',
  seats = 'seats',
  seats_range = 'seats_range',
  seller_type = 'seller_type',
  style = 'style',
  tax_per_year = 'tax_per_year',
  trailer_axle_number = 'trailer_axle_number',
  vat_status = 'vat_status',
  wheelbase = 'wheelbase',
  year = 'year'
}

export enum FacetGroupStatus {
  DISABLED = 'DISABLED',
  EMPTY = 'EMPTY',
  ENABLED = 'ENABLED'
}

export enum FacetName {
  acceleration_values = 'acceleration_values',
  advertising_location = 'advertising_location',
  aggregated_trim = 'aggregated_trim',
  annual_tax_values = 'annual_tax_values',
  axle_config = 'axle_config',
  battery_charge_time_values = 'battery_charge_time_values',
  battery_quick_charge_time_values = 'battery_quick_charge_time_values',
  battery_range_values = 'battery_range_values',
  bedroom_layout = 'bedroom_layout',
  berth = 'berth',
  body_type = 'body_type',
  boot_size_values = 'boot_size_values',
  cab_type = 'cab_type',
  co2_emission_values = 'co2_emission_values',
  colour = 'colour',
  /** @deprecated No longer supported */
  digital_retailing = 'digital_retailing',
  distance = 'distance',
  doors_values = 'doors_values',
  driver_position = 'driver_position',
  drivetrain = 'drivetrain',
  emission_class = 'emission_class',
  end_layout = 'end_layout',
  engine_power = 'engine_power',
  engine_size = 'engine_size',
  engine_size_cc = 'engine_size_cc',
  exterior_length = 'exterior_length',
  finance = 'finance',
  frame_size_cm = 'frame_size_cm',
  fuel_consumption_values = 'fuel_consumption_values',
  fuel_type = 'fuel_type',
  gross_trailer_weight = 'gross_trailer_weight',
  gross_vehicle_weight = 'gross_vehicle_weight',
  home_delivery_adverts = 'home_delivery_adverts',
  hours_used = 'hours_used',
  insurance_group = 'insurance_group',
  is_manufacturer_approved = 'is_manufacturer_approved',
  is_seller_defined_used = 'is_seller_defined_used',
  is_writeoff = 'is_writeoff',
  keywords = 'keywords',
  lat_long = 'lat_long',
  licence_type = 'licence_type',
  location = 'location',
  make = 'make',
  mileage = 'mileage',
  model = 'model',
  monthly_price = 'monthly_price',
  mtplm = 'mtplm',
  ni_only = 'ni_only',
  part_exchange_available = 'part_exchange_available',
  poa = 'poa',
  postcode = 'postcode',
  price = 'price',
  price_search_type = 'price_search_type',
  retailer_id = 'retailer_id',
  seats = 'seats',
  seats_values = 'seats_values',
  seller_type = 'seller_type',
  style = 'style',
  sub_style = 'sub_style',
  trailer_axle_number = 'trailer_axle_number',
  transmission = 'transmission',
  ulez_compliant = 'ulez_compliant',
  vat_status = 'vat_status',
  wheelbase = 'wheelbase',
  with_digital_retailing = 'with_digital_retailing',
  with_manufacturer_rrp_saving = 'with_manufacturer_rrp_saving',
  year_manufactured = 'year_manufactured'
}

export type FetchPartExchangeInput = {
  readonly dealId: Scalars['String']['input'];
  readonly partExchangeId: Scalars['String']['input'];
};

export type FilterInput = {
  readonly filter: FilterName;
  readonly selected?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
};

export enum FilterName {
  acceleration_values = 'acceleration_values',
  advertising_location = 'advertising_location',
  aggregated_trim = 'aggregated_trim',
  annual_tax_values = 'annual_tax_values',
  axle_config = 'axle_config',
  battery_charge_time_values = 'battery_charge_time_values',
  battery_quick_charge_time_values = 'battery_quick_charge_time_values',
  battery_range_values = 'battery_range_values',
  bedroom_layout = 'bedroom_layout',
  berth = 'berth',
  body_type = 'body_type',
  boot_size_values = 'boot_size_values',
  cab_type = 'cab_type',
  capability = 'capability',
  co2_emission_values = 'co2_emission_values',
  colour = 'colour',
  deposit = 'deposit',
  distance = 'distance',
  doors = 'doors',
  doors_values = 'doors_values',
  driver_position = 'driver_position',
  drivetrain = 'drivetrain',
  emission_class = 'emission_class',
  end_layout = 'end_layout',
  fuel_consumption_values = 'fuel_consumption_values',
  fuel_type = 'fuel_type',
  home_delivery_adverts = 'home_delivery_adverts',
  insurance_group = 'insurance_group',
  is_manufacturer_approved = 'is_manufacturer_approved',
  is_seller_defined_used = 'is_seller_defined_used',
  is_writeoff = 'is_writeoff',
  keywords = 'keywords',
  lat_long = 'lat_long',
  licence_type = 'licence_type',
  location = 'location',
  make = 'make',
  max_engine_power = 'max_engine_power',
  max_engine_size = 'max_engine_size',
  max_engine_size_cc = 'max_engine_size_cc',
  max_exterior_length = 'max_exterior_length',
  max_frame_size_cm = 'max_frame_size_cm',
  max_gross_trailer_weight = 'max_gross_trailer_weight',
  max_gross_vehicle_weight = 'max_gross_vehicle_weight',
  max_hours_used = 'max_hours_used',
  max_mileage = 'max_mileage',
  max_monthly_price = 'max_monthly_price',
  max_mtplm = 'max_mtplm',
  max_price = 'max_price',
  max_seats = 'max_seats',
  max_year_manufactured = 'max_year_manufactured',
  min_engine_power = 'min_engine_power',
  min_engine_size = 'min_engine_size',
  min_engine_size_cc = 'min_engine_size_cc',
  min_exterior_length = 'min_exterior_length',
  min_frame_size_cm = 'min_frame_size_cm',
  min_gross_trailer_weight = 'min_gross_trailer_weight',
  min_gross_vehicle_weight = 'min_gross_vehicle_weight',
  min_hours_used = 'min_hours_used',
  min_mileage = 'min_mileage',
  min_monthly_price = 'min_monthly_price',
  min_mtplm = 'min_mtplm',
  min_price = 'min_price',
  min_seats = 'min_seats',
  min_year_manufactured = 'min_year_manufactured',
  model = 'model',
  modified_customer = 'modified_customer',
  monthly_price = 'monthly_price',
  ni_only = 'ni_only',
  part_exchange_available = 'part_exchange_available',
  poa = 'poa',
  postcode = 'postcode',
  price_search_type = 'price_search_type',
  retailer_id = 'retailer_id',
  seats_values = 'seats_values',
  seller_type = 'seller_type',
  style = 'style',
  sub_style = 'sub_style',
  term = 'term',
  trailer_axle_number = 'trailer_axle_number',
  transmission = 'transmission',
  ulez_compliant = 'ulez_compliant',
  vat_status = 'vat_status',
  wheelbase = 'wheelbase',
  with_digital_retailing = 'with_digital_retailing',
  with_manufacturer_rrp_saving = 'with_manufacturer_rrp_saving',
  yearly_mileage = 'yearly_mileage'
}

export type FinanceApplicationLeadMetadata = {
  readonly site: Scalars['String']['input'];
};

export enum FinanceApplicationLenderProposalStatus {
  APPROVED = 'APPROVED',
  BROKER_APPROVED = 'BROKER_APPROVED',
  CONDITIONAL_APPROVED = 'CONDITIONAL_APPROVED',
  DECLINED = 'DECLINED',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS'
}

export type FinanceApplicationRequestTerms = {
  readonly deposit: Scalars['Float']['input'];
  readonly estimatedAnnualMileage: Scalars['Int']['input'];
  readonly termMonths: Scalars['Int']['input'];
};

export enum FinanceDisclaimerComponentType {
  LINK = 'LINK',
  TEXT = 'TEXT'
}

export enum FinanceFeeFrequency {
  IN_FINAL_PAYMENT = 'IN_FINAL_PAYMENT',
  IN_FIRST_PAYMENT = 'IN_FIRST_PAYMENT',
  SPREAD = 'SPREAD',
  SPREAD_NO_INTEREST = 'SPREAD_NO_INTEREST'
}

export enum FinanceInfoAction {
  PROPOSAL_STATUS_UPDATED = 'PROPOSAL_STATUS_UPDATED',
  REPROPOSAL_STATUS_UPDATED = 'REPROPOSAL_STATUS_UPDATED'
}

export enum FinanceListingProvider {
  SELLER = 'SELLER',
  ZUTO = 'ZUTO'
}

export enum FinanceProvider {
  DEALER = 'DEALER',
  ZUTO = 'ZUTO'
}

export type FinanceTermsInput = {
  readonly cashPrice: Scalars['Float']['input'];
  readonly deposit: Scalars['Float']['input'];
  readonly estimatedAnnualMileage: Scalars['Int']['input'];
  readonly financeType: FinanceTypeInput;
  readonly partExchange?: InputMaybe<Scalars['Int']['input']>;
  readonly settlement: Scalars['Int']['input'];
  readonly settlementLenderReference?: InputMaybe<Scalars['String']['input']>;
  readonly term: Scalars['Int']['input'];
};

export enum FinanceTypeInput {
  CS = 'CS',
  HP = 'HP',
  PCP = 'PCP'
}

export enum GenderInput {
  FEMALE = 'FEMALE',
  MALE = 'MALE'
}

export enum GenerationProductionStatus {
  ANY = 'ANY',
  CURRENT = 'CURRENT'
}

export enum HoldingFeeAttachmentType {
  HOLDING_FEE = 'HOLDING_FEE'
}

export type HomeDelivery = {
  readonly costPerMile?: InputMaybe<Scalars['Float']['input']>;
  readonly flatFee?: InputMaybe<Scalars['Float']['input']>;
  readonly freeDeliveryMiles?: InputMaybe<Scalars['Int']['input']>;
};

export enum InProgressJourney {
  AUTOMATED_ABANDONED_WITH_FINANCE_APPLICATION = 'AUTOMATED_ABANDONED_WITH_FINANCE_APPLICATION',
  DEAL_BUILDER = 'DEAL_BUILDER',
  ENQUIRY = 'ENQUIRY',
  RESERVATION = 'RESERVATION',
  UNKNOWN = 'UNKNOWN'
}

export type InStockLeasingAlternativesInput = {
  readonly advertId: Scalars['String']['input'];
  readonly channel: Channel;
  readonly make: Scalars['String']['input'];
  readonly model: Scalars['String']['input'];
  readonly size?: InputMaybe<Scalars['Int']['input']>;
};

export type InitialiseDealInput = {
  readonly completed?: InputMaybe<Scalars['String']['input']>;
  readonly dealContext: DealContext;
  readonly started?: InputMaybe<Scalars['String']['input']>;
};

export enum InitiatedJourney {
  DEAL_BUILDER = 'DEAL_BUILDER',
  RESERVATION = 'RESERVATION'
}

export enum InitiatedJourneyResponse {
  DEAL_BUILDER = 'DEAL_BUILDER',
  ENQUIRY = 'ENQUIRY',
  RESERVATION = 'RESERVATION',
  UNKNOWN = 'UNKNOWN'
}

export enum InterceptType {
  NEW = 'NEW'
}

export type Journey = {
  readonly name: JourneyName;
};

export enum JourneyName {
  DealBuilder = 'DealBuilder',
  HoldingFee = 'HoldingFee',
  Standalone = 'Standalone'
}

export type LeaseEnquiryInput = {
  readonly annualMileage: Scalars['Int']['input'];
  readonly autoramaVehicleId?: InputMaybe<Scalars['Int']['input']>;
  readonly created?: InputMaybe<Scalars['DateTime']['input']>;
  readonly depositMonths: Scalars['Int']['input'];
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly middleName?: InputMaybe<Scalars['String']['input']>;
  readonly monthlyPayment: LeasingAmountWithPenniesInput;
  readonly paint: Scalars['String']['input'];
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly product: Scalars['String']['input'];
  readonly source: LeasingSourceEnum;
  readonly stockBatchId?: InputMaybe<Scalars['Int']['input']>;
  readonly surname?: InputMaybe<Scalars['String']['input']>;
  readonly termMonths: Scalars['Int']['input'];
  readonly upholstery: Scalars['String']['input'];
  readonly vehicleType: LeasingVehicleTypeEnum;
};

export type LeasingAddressInput = {
  readonly buildingName?: InputMaybe<Scalars['String']['input']>;
  readonly buildingNumber?: InputMaybe<Scalars['String']['input']>;
  readonly county?: InputMaybe<Scalars['String']['input']>;
  readonly endedOn?: InputMaybe<Scalars['Date']['input']>;
  readonly lineOne?: InputMaybe<Scalars['String']['input']>;
  readonly lineTwo?: InputMaybe<Scalars['String']['input']>;
  readonly postcode?: InputMaybe<Scalars['String']['input']>;
  readonly propertyStatus?: InputMaybe<Scalars['String']['input']>;
  readonly startedOn?: InputMaybe<Scalars['Date']['input']>;
  readonly subBuildingName?: InputMaybe<Scalars['String']['input']>;
  readonly town?: InputMaybe<Scalars['String']['input']>;
};

export type LeasingAffordabilityInput = {
  readonly annualFutureIncome?: InputMaybe<LeasingAmountWithoutPenniesInput>;
  readonly annualHouseholdIncome?: InputMaybe<LeasingAmountWithoutPenniesInput>;
  readonly changeInCircumstances?: InputMaybe<Scalars['Boolean']['input']>;
  readonly existingVehicleRegistration?: InputMaybe<Scalars['String']['input']>;
  readonly monthlyChildcare?: InputMaybe<LeasingAmountWithoutPenniesInput>;
  readonly monthlyCreditCardPayments?: InputMaybe<LeasingAmountWithoutPenniesInput>;
  readonly monthlyExistingVehicleFinance?: InputMaybe<LeasingAmountWithoutPenniesInput>;
  readonly monthlyFoodAndClothes?: InputMaybe<LeasingAmountWithoutPenniesInput>;
  readonly monthlyFuel?: InputMaybe<LeasingAmountWithoutPenniesInput>;
  readonly monthlyIncome?: InputMaybe<LeasingAmountWithoutPenniesInput>;
  readonly monthlyInsurance?: InputMaybe<LeasingAmountWithoutPenniesInput>;
  readonly monthlyOtherCredit?: InputMaybe<LeasingAmountWithoutPenniesInput>;
  readonly monthlyPhoneAndInternet?: InputMaybe<LeasingAmountWithoutPenniesInput>;
  readonly monthlyRentOrMortgage?: InputMaybe<LeasingAmountWithoutPenniesInput>;
  readonly monthlyUtilities?: InputMaybe<LeasingAmountWithoutPenniesInput>;
  readonly monthlyVehicleFinance?: InputMaybe<LeasingAmountWithoutPenniesInput>;
  readonly numberOfAdultsInHousehold?: InputMaybe<Scalars['Int']['input']>;
  readonly replacingExistingLoan?: InputMaybe<Scalars['Boolean']['input']>;
  readonly studentLoan?: InputMaybe<Scalars['Boolean']['input']>;
};

export type LeasingAmountWithPenniesInput = {
  readonly amountGBP?: InputMaybe<Scalars['Float']['input']>;
};

export type LeasingAmountWithoutPenniesInput = {
  readonly amountGBP?: InputMaybe<Scalars['Int']['input']>;
};

export type LeasingApplicantInput = {
  readonly bankAccountHolderName?: InputMaybe<Scalars['String']['input']>;
  readonly bankAccountNumber?: InputMaybe<Scalars['String']['input']>;
  readonly bankName?: InputMaybe<Scalars['String']['input']>;
  readonly bankSortCode?: InputMaybe<Scalars['String']['input']>;
  readonly bankStartedOn?: InputMaybe<Scalars['Date']['input']>;
  readonly countryOfBirthCode?: InputMaybe<Scalars['String']['input']>;
  readonly dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly gender?: InputMaybe<LeasingGenderEnum>;
  readonly maritalStatus?: InputMaybe<Scalars['String']['input']>;
  readonly middleName?: InputMaybe<Scalars['String']['input']>;
  readonly mobile?: InputMaybe<Scalars['String']['input']>;
  readonly nationality?: InputMaybe<Scalars['String']['input']>;
  readonly numberOfDependants?: InputMaybe<Scalars['Int']['input']>;
  readonly surname?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
};

export type LeasingApplicationCompanyAddressInput = {
  readonly buildingName?: InputMaybe<Scalars['String']['input']>;
  readonly buildingNumber?: InputMaybe<Scalars['String']['input']>;
  readonly county?: InputMaybe<Scalars['String']['input']>;
  readonly lineOne?: InputMaybe<Scalars['String']['input']>;
  readonly lineTwo?: InputMaybe<Scalars['String']['input']>;
  readonly postcode?: InputMaybe<Scalars['String']['input']>;
  readonly subBuildingName?: InputMaybe<Scalars['String']['input']>;
  readonly town?: InputMaybe<Scalars['String']['input']>;
};

export type LeasingApplicationCompanyCountriesOfTradeInput = {
  readonly countryCode?: InputMaybe<Scalars['String']['input']>;
  readonly turnoverPercentage?: InputMaybe<Scalars['Int']['input']>;
};

export type LeasingApplicationCompanyDirectorInput = {
  readonly currentAddress?: InputMaybe<LeasingAddressInput>;
  readonly dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly gender?: InputMaybe<LeasingGenderEnum>;
  readonly middleName?: InputMaybe<Scalars['String']['input']>;
  readonly nationality?: InputMaybe<Scalars['String']['input']>;
  readonly numberOfDependants?: InputMaybe<Scalars['Int']['input']>;
  readonly personNumber?: InputMaybe<Scalars['String']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly previousAddresses?: InputMaybe<ReadonlyArray<InputMaybe<LeasingAddressInput>>>;
  readonly shareholdingPercentage?: InputMaybe<Scalars['Int']['input']>;
  readonly surname?: InputMaybe<Scalars['String']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
};

export type LeasingApplicationCompanyInput = {
  readonly annualCostOfSales?: InputMaybe<LeasingAmountWithoutPenniesInput>;
  readonly annualExpenses?: InputMaybe<LeasingAmountWithoutPenniesInput>;
  readonly annualTurnover?: InputMaybe<LeasingAmountWithoutPenniesInput>;
  readonly consent?: InputMaybe<LeasingConsentInput>;
  readonly countriesOfTrade?: InputMaybe<ReadonlyArray<InputMaybe<LeasingApplicationCompanyCountriesOfTradeInput>>>;
  readonly directors?: InputMaybe<ReadonlyArray<InputMaybe<LeasingApplicationCompanyDirectorInput>>>;
  readonly email?: InputMaybe<Scalars['String']['input']>;
  readonly natureOfBusiness?: InputMaybe<LeasingCompanyNatureOfBusinessInput>;
  readonly number?: InputMaybe<Scalars['String']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly previouslySoleTraderOrPartnership?: InputMaybe<Scalars['Boolean']['input']>;
  readonly registeredAddress?: InputMaybe<LeasingApplicationCompanyAddressInput>;
  readonly tradingAddress?: InputMaybe<LeasingApplicationCompanyAddressInput>;
  readonly tradingName?: InputMaybe<Scalars['String']['input']>;
  readonly tradingStartedOn?: InputMaybe<Scalars['Date']['input']>;
  readonly type?: InputMaybe<Scalars['String']['input']>;
  readonly vatNumber?: InputMaybe<Scalars['String']['input']>;
};

export type LeasingApplicationInput = {
  readonly affordability?: InputMaybe<LeasingAffordabilityInput>;
  readonly applicant?: InputMaybe<LeasingApplicantInput>;
  readonly applicationId?: InputMaybe<Scalars['String']['input']>;
  readonly company?: InputMaybe<LeasingApplicationCompanyInput>;
  readonly consent?: InputMaybe<LeasingConsentInput>;
  readonly currentAddress?: InputMaybe<LeasingAddressInput>;
  readonly currentEmployment?: InputMaybe<LeasingEmploymentInput>;
  readonly previousAddresses?: InputMaybe<ReadonlyArray<InputMaybe<LeasingAddressInput>>>;
  readonly previousEmployment?: InputMaybe<ReadonlyArray<InputMaybe<LeasingEmploymentInput>>>;
  readonly proposals?: InputMaybe<ReadonlyArray<InputMaybe<LeasingProposalInput>>>;
  readonly source?: InputMaybe<LeasingSourceEnum>;
  readonly status?: InputMaybe<LeasingStatusEnum>;
};

export type LeasingCompanyNatureOfBusinessInput = {
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly sicCode?: InputMaybe<Scalars['String']['input']>;
};

export enum LeasingCompanyStatusEnum {
  Active = 'Active',
  Administration = 'Administration',
  Closed = 'Closed',
  ConvertedClosed = 'ConvertedClosed',
  Dissolved = 'Dissolved',
  InsolvencyProceedings = 'InsolvencyProceedings',
  Liquidation = 'Liquidation',
  Open = 'Open',
  Receivership = 'Receivership',
  Registered = 'Registered',
  Removed = 'Removed',
  VoluntaryArrangement = 'VoluntaryArrangement'
}

export type LeasingConsentInput = {
  readonly authorisedByCompany?: InputMaybe<Scalars['Boolean']['input']>;
  readonly emailConsent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly privacyPolicy?: InputMaybe<Scalars['Boolean']['input']>;
  readonly smsConsent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly suitabilityConsent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly termsAndConditions?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum LeasingCreditCheckEnum {
  FAIR = 'FAIR',
  GOOD = 'GOOD',
  LOW = 'LOW',
  NO_RESULT = 'NO_RESULT',
  VERY_HIGH = 'VERY_HIGH',
  VERY_LOW = 'VERY_LOW'
}

export type LeasingEmploymentAddressInput = {
  readonly buildingName?: InputMaybe<Scalars['String']['input']>;
  readonly buildingNumber?: InputMaybe<Scalars['String']['input']>;
  readonly county?: InputMaybe<Scalars['String']['input']>;
  readonly lineOne?: InputMaybe<Scalars['String']['input']>;
  readonly lineTwo?: InputMaybe<Scalars['String']['input']>;
  readonly postcode?: InputMaybe<Scalars['String']['input']>;
  readonly subBuildingName?: InputMaybe<Scalars['String']['input']>;
  readonly town?: InputMaybe<Scalars['String']['input']>;
};

export type LeasingEmploymentInput = {
  readonly address?: InputMaybe<LeasingEmploymentAddressInput>;
  readonly annualIncome?: InputMaybe<LeasingAmountWithoutPenniesInput>;
  readonly contract?: InputMaybe<Scalars['String']['input']>;
  readonly endedOn?: InputMaybe<Scalars['Date']['input']>;
  readonly jobTitle?: InputMaybe<Scalars['String']['input']>;
  readonly name?: InputMaybe<Scalars['String']['input']>;
  readonly phoneNumber?: InputMaybe<Scalars['String']['input']>;
  readonly startedOn?: InputMaybe<Scalars['Date']['input']>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
};

export type LeasingFinanceTermsInput = {
  readonly annualMileage?: InputMaybe<Scalars['Int']['input']>;
  readonly depositMonths?: InputMaybe<Scalars['Int']['input']>;
  readonly monthlyPayment?: InputMaybe<LeasingAmountWithPenniesInput>;
  readonly product?: InputMaybe<Scalars['String']['input']>;
  readonly termMonths?: InputMaybe<Scalars['Int']['input']>;
};

export enum LeasingGenderEnum {
  Female = 'Female',
  Male = 'Male',
  Undisclosed = 'Undisclosed'
}

export type LeasingProposalInput = {
  readonly autoramaFunderId?: InputMaybe<Scalars['Int']['input']>;
  readonly financeTerms?: InputMaybe<LeasingFinanceTermsInput>;
  readonly quantity?: InputMaybe<Scalars['Int']['input']>;
  readonly stockBatchId?: InputMaybe<Scalars['Int']['input']>;
  readonly vehicle?: InputMaybe<LeasingVehicleInput>;
};

export enum LeasingSourceEnum {
  AutoTrader = 'AutoTrader',
  Vanarama = 'Vanarama'
}

export enum LeasingStatusEnum {
  COMPLETE = 'COMPLETE'
}

export type LeasingVehicleInput = {
  readonly autoramaVehicleId?: InputMaybe<Scalars['Int']['input']>;
  readonly paint?: InputMaybe<Scalars['String']['input']>;
  readonly upholstery?: InputMaybe<Scalars['String']['input']>;
  readonly vehicleType?: InputMaybe<LeasingVehicleTypeEnum>;
};

export enum LeasingVehicleTypeEnum {
  CAR = 'CAR',
  LCV = 'LCV'
}

export enum ListingType {
  FEATURED_LISTING = 'FEATURED_LISTING',
  GPT_LISTING = 'GPT_LISTING',
  LEASING_LISTING = 'LEASING_LISTING',
  NATURAL_LISTING = 'NATURAL_LISTING',
  PROMOTED_LISTING = 'PROMOTED_LISTING',
  YOU_MAY_ALSO_LIKE = 'YOU_MAY_ALSO_LIKE'
}

export enum LocalUserLevel {
  SIGNED_IN = 'SIGNED_IN',
  SIGNED_IN_RECENTLY = 'SIGNED_IN_RECENTLY'
}

export type MakeModel = {
  readonly make?: InputMaybe<Scalars['String']['input']>;
  readonly model?: InputMaybe<Scalars['String']['input']>;
};

export enum MaritalStatusInput {
  CIVIL_PARTNERSHIP = 'CIVIL_PARTNERSHIP',
  COHABITING = 'COHABITING',
  DISSOLVED_CIVIL_PARTNERSHIP = 'DISSOLVED_CIVIL_PARTNERSHIP',
  DIVORCED = 'DIVORCED',
  MARRIED = 'MARRIED',
  SEPARATED = 'SEPARATED',
  SINGLE = 'SINGLE',
  WIDOWED = 'WIDOWED'
}

export type MarkDealConversationAsReadInput = {
  readonly dealId?: InputMaybe<Scalars['String']['input']>;
};

export type MarketingPreferenceInput = {
  readonly email: MarketingPreferenceState;
  readonly preference: Scalars['String']['input'];
  readonly push: MarketingPreferenceState;
};

export enum MarketingPreferenceState {
  OFF = 'OFF',
  ON = 'ON',
  UNAVAILABLE = 'UNAVAILABLE'
}

export type MarketingPreferencesInput = {
  readonly preferences: ReadonlyArray<MarketingPreferenceInput>;
};

export type MessagingContextInput = {
  readonly retailer: MessagingRetailerInput;
};

export type MessagingData = {
  readonly context: MessagingContextInput;
  readonly message: Scalars['String']['input'];
};

export type MessagingRetailerInput = {
  readonly id: Scalars['String']['input'];
};

export enum NationalityCodeInput {
  ABW = 'ABW',
  AFG = 'AFG',
  AGO = 'AGO',
  AIA = 'AIA',
  ALB = 'ALB',
  AND = 'AND',
  ARE = 'ARE',
  ARG = 'ARG',
  ARM = 'ARM',
  ASM = 'ASM',
  ATA = 'ATA',
  ATG = 'ATG',
  AUS = 'AUS',
  AUT = 'AUT',
  AZE = 'AZE',
  BDI = 'BDI',
  BEL = 'BEL',
  BEN = 'BEN',
  BES = 'BES',
  BFA = 'BFA',
  BGD = 'BGD',
  BGR = 'BGR',
  BHR = 'BHR',
  BHS = 'BHS',
  BIH = 'BIH',
  BLM = 'BLM',
  BLR = 'BLR',
  BLZ = 'BLZ',
  BMU = 'BMU',
  BOL = 'BOL',
  BRA = 'BRA',
  BRB = 'BRB',
  BRN = 'BRN',
  BTN = 'BTN',
  BVT = 'BVT',
  BWA = 'BWA',
  CAF = 'CAF',
  CAN = 'CAN',
  CCK = 'CCK',
  CHE = 'CHE',
  CHL = 'CHL',
  CHN = 'CHN',
  CIV = 'CIV',
  CMR = 'CMR',
  COD = 'COD',
  COG = 'COG',
  COK = 'COK',
  COL = 'COL',
  COM = 'COM',
  CPV = 'CPV',
  CRI = 'CRI',
  CUB = 'CUB',
  CUW = 'CUW',
  CXR = 'CXR',
  CYM = 'CYM',
  CYP = 'CYP',
  CZE = 'CZE',
  DEU = 'DEU',
  DJI = 'DJI',
  DMA = 'DMA',
  DNK = 'DNK',
  DOM = 'DOM',
  DZA = 'DZA',
  ECU = 'ECU',
  EGY = 'EGY',
  ERI = 'ERI',
  ESP = 'ESP',
  EST = 'EST',
  ETH = 'ETH',
  FIN = 'FIN',
  FJI = 'FJI',
  FLK = 'FLK',
  FRA = 'FRA',
  FRO = 'FRO',
  FSM = 'FSM',
  GAB = 'GAB',
  GBR = 'GBR',
  GEO = 'GEO',
  GGY = 'GGY',
  GHA = 'GHA',
  GIB = 'GIB',
  GIN = 'GIN',
  GLP = 'GLP',
  GMB = 'GMB',
  GNB = 'GNB',
  GNQ = 'GNQ',
  GRC = 'GRC',
  GRD = 'GRD',
  GRL = 'GRL',
  GTM = 'GTM',
  GUM = 'GUM',
  GUY = 'GUY',
  HKG = 'HKG',
  HND = 'HND',
  HRV = 'HRV',
  HTI = 'HTI',
  HUN = 'HUN',
  IDN = 'IDN',
  IMN = 'IMN',
  IND = 'IND',
  IOT = 'IOT',
  IRL = 'IRL',
  IRN = 'IRN',
  IRQ = 'IRQ',
  ISL = 'ISL',
  ISR = 'ISR',
  ITA = 'ITA',
  JAM = 'JAM',
  JEY = 'JEY',
  JOR = 'JOR',
  JPN = 'JPN',
  KAZ = 'KAZ',
  KEN = 'KEN',
  KGZ = 'KGZ',
  KHM = 'KHM',
  KIR = 'KIR',
  KNA = 'KNA',
  KOR = 'KOR',
  KWT = 'KWT',
  LAO = 'LAO',
  LBN = 'LBN',
  LBR = 'LBR',
  LBY = 'LBY',
  LCA = 'LCA',
  LIE = 'LIE',
  LKA = 'LKA',
  LSO = 'LSO',
  LTU = 'LTU',
  LUX = 'LUX',
  LVA = 'LVA',
  MAC = 'MAC',
  MAF = 'MAF',
  MAR = 'MAR',
  MCO = 'MCO',
  MDA = 'MDA',
  MDG = 'MDG',
  MDV = 'MDV',
  MEX = 'MEX',
  MHL = 'MHL',
  MKD = 'MKD',
  MLI = 'MLI',
  MLT = 'MLT',
  MMR = 'MMR',
  MNE = 'MNE',
  MNG = 'MNG',
  MNP = 'MNP',
  MOZ = 'MOZ',
  MRT = 'MRT',
  MSR = 'MSR',
  MTQ = 'MTQ',
  MUS = 'MUS',
  MWI = 'MWI',
  MYS = 'MYS',
  MYT = 'MYT',
  NAM = 'NAM',
  NCL = 'NCL',
  NER = 'NER',
  NFK = 'NFK',
  NGA = 'NGA',
  NIC = 'NIC',
  NIU = 'NIU',
  NLD = 'NLD',
  NOR = 'NOR',
  NPL = 'NPL',
  NRU = 'NRU',
  NZL = 'NZL',
  OMN = 'OMN',
  PAK = 'PAK',
  PAN = 'PAN',
  PCN = 'PCN',
  PER = 'PER',
  PHL = 'PHL',
  PLW = 'PLW',
  PNG = 'PNG',
  POL = 'POL',
  PRI = 'PRI',
  PRK = 'PRK',
  PRT = 'PRT',
  PRY = 'PRY',
  PSE = 'PSE',
  QAT = 'QAT',
  ROU = 'ROU',
  RUS = 'RUS',
  RWA = 'RWA',
  SAU = 'SAU',
  SDN = 'SDN',
  SEN = 'SEN',
  SGP = 'SGP',
  SHN = 'SHN',
  SJM = 'SJM',
  SLB = 'SLB',
  SLE = 'SLE',
  SLV = 'SLV',
  SMR = 'SMR',
  SOM = 'SOM',
  SPM = 'SPM',
  SRB = 'SRB',
  SSD = 'SSD',
  SUR = 'SUR',
  SVK = 'SVK',
  SVN = 'SVN',
  SWE = 'SWE',
  SWZ = 'SWZ',
  SXM = 'SXM',
  SYC = 'SYC',
  SYR = 'SYR',
  TCA = 'TCA',
  TCD = 'TCD',
  TGO = 'TGO',
  THA = 'THA',
  TJK = 'TJK',
  TKL = 'TKL',
  TKM = 'TKM',
  TLS = 'TLS',
  TON = 'TON',
  TTO = 'TTO',
  TUN = 'TUN',
  TUR = 'TUR',
  TUV = 'TUV',
  TWN = 'TWN',
  TZA = 'TZA',
  UGA = 'UGA',
  UKR = 'UKR',
  URY = 'URY',
  USA = 'USA',
  UZB = 'UZB',
  VAT = 'VAT',
  VCT = 'VCT',
  VEN = 'VEN',
  VGB = 'VGB',
  VIR = 'VIR',
  VNM = 'VNM',
  VUT = 'VUT',
  WLF = 'WLF',
  WSM = 'WSM',
  YEM = 'YEM',
  ZAF = 'ZAF',
  ZMB = 'ZMB',
  ZWE = 'ZWE'
}

export enum PageType {
  BodyType = 'BodyType',
  Colour = 'Colour',
  DealerDirectory = 'DealerDirectory',
  EngineSize = 'EngineSize',
  FuelType = 'FuelType',
  Generic = 'Generic',
  GenericSeo = 'GenericSeo',
  LeasingMake = 'LeasingMake',
  LeasingMakeModel = 'LeasingMakeModel',
  Make = 'Make',
  MakeModel = 'MakeModel',
  Transmission = 'Transmission'
}

export enum PartExchangeJourney {
  VALUATION_PROVIDED = 'VALUATION_PROVIDED',
  VEHICLE_ONLY = 'VEHICLE_ONLY'
}

export type PartExchangeOutstandingFinanceInput = {
  readonly lender: Scalars['String']['input'];
  readonly value: Scalars['Int']['input'];
};

export enum PartExchangeValuationCondition {
  EXCELLENT = 'EXCELLENT',
  FAIR = 'FAIR',
  GOOD = 'GOOD',
  GREAT = 'GREAT',
  POOR = 'POOR'
}

export enum PartExchangeVehicleType {
  CAR = 'CAR',
  VAN = 'VAN'
}

export type PaymentIntentCompliance = {
  readonly marketing: Scalars['Boolean']['input'];
  readonly shareWithRetailer: Scalars['Boolean']['input'];
};

export type Pricing = {
  readonly adminFee?: InputMaybe<Scalars['Int']['input']>;
  readonly currency?: InputMaybe<Scalars['String']['input']>;
  readonly price?: InputMaybe<Scalars['Int']['input']>;
  readonly suppliedPrice?: InputMaybe<Scalars['Int']['input']>;
  readonly totalPrice?: InputMaybe<Scalars['Int']['input']>;
  readonly totalSuppliedPrice?: InputMaybe<Scalars['Int']['input']>;
  readonly vatStatus?: InputMaybe<VatStatus>;
};

export enum ProductionStatus {
  InProduction = 'InProduction',
  PreProduction = 'PreProduction'
}

export type PurchaseVehicleCheckOrderInput = {
  readonly advertId?: InputMaybe<Scalars['String']['input']>;
  readonly orderId: Scalars['String']['input'];
  readonly paymentMethodId: Scalars['String']['input'];
  readonly registration?: InputMaybe<Scalars['String']['input']>;
};

export enum QuoteSubType {
  CS = 'CS',
  HP = 'HP',
  PCP = 'PCP'
}

export type RepresentativeExampleInput = {
  readonly advertId: Scalars['String']['input'];
  readonly compareToIndexedQuote?: InputMaybe<Scalars['String']['input']>;
  readonly customerRef?: InputMaybe<Scalars['String']['input']>;
  readonly depositAmount: Scalars['String']['input'];
  readonly financeShowcaseOverride?: InputMaybe<Scalars['String']['input']>;
  readonly mileage: Scalars['String']['input'];
  readonly settlement?: InputMaybe<Scalars['String']['input']>;
  readonly term: Scalars['String']['input'];
  readonly termsType?: InputMaybe<Scalars['String']['input']>;
};

export enum ReservationErrorType {
  PROFANITY_CHECK_FAILED = 'PROFANITY_CHECK_FAILED',
  UNABLE_TO_GET_PAYMENT_SESSION = 'UNABLE_TO_GET_PAYMENT_SESSION',
  UPDATE_FAILED = 'UPDATE_FAILED'
}

export type ReservationJourney = {
  readonly name: JourneyName;
  readonly platform: Scalars['String']['input'];
};

export enum ReservationPaymentStatus {
  AutoTraderInitiatedRefund = 'AutoTraderInitiatedRefund',
  ConsumerInitiatedRefund = 'ConsumerInitiatedRefund',
  ConsumerInitiatedTransfer = 'ConsumerInitiatedTransfer',
  Initiated = 'Initiated',
  InitiatedTransfer = 'InitiatedTransfer',
  RefundComplete = 'RefundComplete',
  ReservationHeld = 'ReservationHeld',
  RetailerInitiatedRefund = 'RetailerInitiatedRefund',
  RetailerInitiatedTransfer = 'RetailerInitiatedTransfer',
  RetailerPaid = 'RetailerPaid',
  TransferComplete = 'TransferComplete'
}

export type ReservationUpdateData = {
  readonly consumerEmail?: InputMaybe<Scalars['String']['input']>;
  readonly paymentStatus?: InputMaybe<ReservationPaymentStatus>;
  readonly reasonCode?: InputMaybe<Scalars['String']['input']>;
  readonly reasonText?: InputMaybe<Scalars['String']['input']>;
};

export type ResidentialAddressInput = {
  readonly address: AddressInput;
  readonly residentialStatus: ResidentialStatusInput;
  readonly timeAtAddress: TimeAtAddressInput;
};

export enum ResidentialStatus {
  COUNCIL_TENANT = 'COUNCIL_TENANT',
  HOMEOWNER = 'HOMEOWNER',
  HOUSING_ASSOCIATION_TENANT = 'HOUSING_ASSOCIATION_TENANT',
  LIVING_WITH_FAMILY = 'LIVING_WITH_FAMILY',
  MILITARY_ACCOMMODATION = 'MILITARY_ACCOMMODATION',
  OTHER = 'OTHER',
  PRIVATE_TENANT = 'PRIVATE_TENANT',
  STUDENT_ACCOMMODATION = 'STUDENT_ACCOMMODATION',
  WORK_ACCOMMODATION = 'WORK_ACCOMMODATION'
}

export enum ResidentialStatusInput {
  COUNCIL_TENANT = 'COUNCIL_TENANT',
  HOMEOWNER = 'HOMEOWNER',
  HOUSING_ASSOCIATION_TENANT = 'HOUSING_ASSOCIATION_TENANT',
  LIVING_WITH_FAMILY = 'LIVING_WITH_FAMILY',
  MILITARY_ACCOMMODATION = 'MILITARY_ACCOMMODATION',
  OTHER = 'OTHER',
  PRIVATE_TENANT = 'PRIVATE_TENANT',
  STUDENT_ACCOMMODATION = 'STUDENT_ACCOMMODATION',
  WORK_ACCOMMODATION = 'WORK_ACCOMMODATION'
}

export type RetailerTermsAndConditionsInput = {
  readonly retailerId: Scalars['String']['input'];
  readonly termsType?: InputMaybe<Scalars['String']['input']>;
};

export enum SEORetailerType {
  new_bike = 'new_bike',
  new_car = 'new_car',
  used_bike = 'used_bike',
  used_car = 'used_car',
  used_caravan = 'used_caravan',
  used_farm = 'used_farm',
  used_motorhome = 'used_motorhome',
  used_plant = 'used_plant',
  used_truck = 'used_truck',
  used_van = 'used_van'
}

export type SavedAdvertSearchReferrerParams = {
  readonly latLong?: InputMaybe<Scalars['String']['input']>;
  readonly postcode?: InputMaybe<Scalars['String']['input']>;
  readonly radius?: InputMaybe<Scalars['String']['input']>;
  readonly sort?: InputMaybe<Scalars['String']['input']>;
};

export type SavedSearchInput = {
  readonly alert: Alert;
  readonly name: Scalars['String']['input'];
  readonly searchUrl: Scalars['String']['input'];
};

export type SearchOptions = {
  readonly advertisingLocations?: InputMaybe<ReadonlyArray<AdvertisingLocation>>;
  readonly channel?: InputMaybe<Scalars['String']['input']>;
  readonly collectionLocationOptions?: InputMaybe<CollectionLocationOptions>;
  readonly financeOption?: InputMaybe<Scalars['String']['input']>;
  readonly postcode?: InputMaybe<Scalars['String']['input']>;
  readonly quickQuote?: InputMaybe<Scalars['Boolean']['input']>;
  readonly replacePriceWithTotalPrice?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SearchResultsInput = {
  readonly channel: Channel;
  readonly facets: ReadonlyArray<FacetName>;
  readonly filters: ReadonlyArray<FilterInput>;
  readonly imageCount?: InputMaybe<Scalars['Int']['input']>;
  readonly listingType?: InputMaybe<ReadonlyArray<ListingType>>;
  readonly page?: InputMaybe<Scalars['Int']['input']>;
  /** A UUID that uniquely identifies the search, must be provided for listings query */
  readonly searchId?: InputMaybe<Scalars['String']['input']>;
  readonly sortBy?: InputMaybe<SearchResultsSort>;
  /** A temporary boolean value to determine whether to use the grid layout */
  readonly useGridLayout?: InputMaybe<Scalars['Boolean']['input']>;
  /** A temporary boolean value to determine whether to use the new facet values for the search evo filters */
  readonly useSearchEvoFacets?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum SearchResultsSort {
  distance = 'distance',
  make = 'make',
  mileage = 'mileage',
  model = 'model',
  most_recent = 'most_recent',
  premium_downgrade = 'premium_downgrade',
  premium_upgrade = 'premium_upgrade',
  price_asc = 'price_asc',
  price_desc = 'price_desc',
  relevance = 'relevance',
  year_asc = 'year_asc',
  year_dsc = 'year_dsc'
}

export enum SellerType {
  PRIVATE = 'PRIVATE',
  TRADE = 'TRADE'
}

export enum SellerTypeResponse {
  PRIVATE = 'PRIVATE',
  RETAILER = 'RETAILER'
}

export type SoftRegistrationData = {
  readonly email: Scalars['String']['input'];
};

export enum SubmitDealInProgressJourney {
  CALL = 'CALL',
  DEAL_BUILDER = 'DEAL_BUILDER',
  ENQUIRY = 'ENQUIRY',
  RESERVATION = 'RESERVATION'
}

export type SubmitDealInput = {
  readonly commitState: CommitStateData;
  readonly dealId: Scalars['String']['input'];
  readonly inProgressJourney: SubmitDealInProgressJourney;
  readonly messaging: MessagingData;
};

export type SubmitDeliveryInput = {
  readonly collectionLocation?: InputMaybe<CollectionLocationInput>;
  readonly consumerPostcode: Scalars['String']['input'];
  readonly dealId: Scalars['String']['input'];
  readonly deliveryOption: DeliveryOption;
  readonly homeDelivery?: InputMaybe<HomeDelivery>;
};

export type TimeAtAddressInput = {
  readonly months: Scalars['Int']['input'];
  readonly years: Scalars['Int']['input'];
};

export type TimeAtEmployerInput = {
  readonly months: Scalars['Int']['input'];
  readonly years: Scalars['Int']['input'];
};

export enum TitleInput {
  DR = 'DR',
  MISS = 'MISS',
  MR = 'MR',
  MRS = 'MRS',
  MS = 'MS',
  OTHER = 'OTHER'
}

export enum TrackingCondition {
  nearly_new = 'nearly_new',
  new = 'new',
  used = 'used'
}

export enum TrackingVehicleCategory {
  bike = 'bike',
  car = 'car',
  caravan = 'caravan',
  cycle = 'cycle',
  farm = 'farm',
  motorhome = 'motorhome',
  plant = 'plant',
  truck = 'truck',
  van = 'van'
}

export type UpdateCommitStateInput = {
  readonly commitState: CommitStateValueInput;
  readonly dealId: Scalars['String']['input'];
};

export type UpdateFinanceApplicationWithEstimatesInput = {
  readonly advertId: Scalars['String']['input'];
  readonly dealId: Scalars['String']['input'];
  readonly financeTerms: FinanceApplicationRequestTerms;
  readonly partExchangeId?: InputMaybe<Scalars['String']['input']>;
  readonly reference: Scalars['String']['input'];
};

export type UpdateHoldingFeeCommitStateInput = {
  readonly commitState: CommitStateValueInput;
  readonly dealId: Scalars['String']['input'];
};

export type UpdatePartExchangeInput = {
  readonly condition?: InputMaybe<PartExchangeValuationCondition>;
  readonly dealId: Scalars['String']['input'];
  readonly modifications?: InputMaybe<Scalars['String']['input']>;
  readonly outstandingFinance?: InputMaybe<PartExchangeOutstandingFinanceInput>;
  readonly partExchangeId: Scalars['String']['input'];
};

export type UpdateSavedSearchInput = {
  readonly alert: Alert;
  readonly canonicalSearchValues: Scalars['String']['input'];
  readonly id: Scalars['String']['input'];
  readonly name: Scalars['String']['input'];
};

export type UpdateUserVehicleDatesInput = {
  readonly contactByEmail?: InputMaybe<Scalars['Boolean']['input']>;
  readonly contactByTextMessages?: InputMaybe<Scalars['Boolean']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly insuranceRenewalDate?: InputMaybe<UserVehicleDateInput>;
  readonly mileageOnLastService?: InputMaybe<Scalars['String']['input']>;
  readonly mobileNumber?: InputMaybe<Scalars['String']['input']>;
  readonly motDate?: InputMaybe<UserVehicleDateInput>;
  readonly nextServiceDate?: InputMaybe<UserVehicleDateInput>;
  readonly postcode?: InputMaybe<Scalars['String']['input']>;
  readonly roadTaxRenewalDate?: InputMaybe<UserVehicleDateInput>;
  readonly sendReminderAtOneDay?: InputMaybe<Scalars['Boolean']['input']>;
  readonly sendReminderAtSevenDays?: InputMaybe<Scalars['Boolean']['input']>;
  readonly sendReminderAtThirtyDays?: InputMaybe<Scalars['Boolean']['input']>;
  readonly typeOfLastService?: InputMaybe<Scalars['String']['input']>;
  readonly vehicleId?: InputMaybe<Scalars['String']['input']>;
  readonly warrantyRenewalDate?: InputMaybe<UserVehicleDateInput>;
};

export type UpdateUserVehicleInput = {
  readonly atDerivativeId?: InputMaybe<Scalars['String']['input']>;
  readonly derivativeName?: InputMaybe<Scalars['String']['input']>;
  readonly mileage?: InputMaybe<Scalars['Int']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly vehicleId: Scalars['String']['input'];
};

export type UserAddressInput = {
  readonly address1: Scalars['String']['input'];
  readonly address2: Scalars['String']['input'];
  readonly county: Scalars['String']['input'];
  readonly postcode: Scalars['String']['input'];
  readonly town: Scalars['String']['input'];
};

export type UserDealSearch = {
  readonly consumerDealStatuses?: InputMaybe<ReadonlyArray<ConsumerDealStatus>>;
};

export enum UserDealSortOption {
  CREATED_ASC = 'CREATED_ASC',
  CREATED_DESC = 'CREATED_DESC',
  DYNAMIC = 'DYNAMIC',
  UPDATED_ASC = 'UPDATED_ASC',
  UPDATED_DESC = 'UPDATED_DESC'
}

export type UserInput = {
  readonly addressList?: InputMaybe<ReadonlyArray<UserAddressInput>>;
  readonly displayName?: InputMaybe<Scalars['String']['input']>;
  readonly firstName: Scalars['String']['input'];
  readonly lastName: Scalars['String']['input'];
  readonly sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  readonly telephoneNumbers?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly tradingName?: InputMaybe<Scalars['String']['input']>;
};

export enum UserLevel {
  SIGNED_IN = 'SIGNED_IN',
  SIGNED_IN_RECENTLY = 'SIGNED_IN_RECENTLY'
}

export enum UserReminderStatus {
  AMBER = 'AMBER',
  GREEN = 'GREEN',
  RED = 'RED'
}

export enum UserRole {
  /** Elevated permissions for users who are 'RECENTLY_SIGNED_IN' */
  ELEVATED = 'ELEVATED',
  /** No permissions required */
  NONE = 'NONE',
  /** User level permissions for users who are 'SIGNED_IN' */
  USER = 'USER'
}

export type UserVehicleDateInput = {
  readonly date?: InputMaybe<Scalars['DateTime']['input']>;
  readonly dateSet?: InputMaybe<Scalars['Boolean']['input']>;
  readonly daysRemaining?: InputMaybe<Scalars['Int']['input']>;
  readonly reminderStatus?: InputMaybe<UserReminderStatus>;
  readonly reminderStatusText?: InputMaybe<Scalars['String']['input']>;
  readonly remindersEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  readonly showAutoDateMessage?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum UserVerificationErrorReason {
  ABANDONED = 'ABANDONED',
  CONSENT_DECLINED = 'CONSENT_DECLINED',
  COUNTRY_NOT_SUPPORTED = 'COUNTRY_NOT_SUPPORTED',
  DEVICE_NOT_SUPPORTED = 'DEVICE_NOT_SUPPORTED',
  DOCUMENT_EXPIRED = 'DOCUMENT_EXPIRED',
  DOCUMENT_TYPE_NOT_SUPPORTED = 'DOCUMENT_TYPE_NOT_SUPPORTED',
  DOCUMENT_UNVERIFIED_OTHER = 'DOCUMENT_UNVERIFIED_OTHER',
  ID_NUMBER_INSUFFICIENT_DOCUMENT_DATA = 'ID_NUMBER_INSUFFICIENT_DOCUMENT_DATA',
  ID_NUMBER_MISMATCH = 'ID_NUMBER_MISMATCH',
  ID_NUMBER_UNVERIFIED_OTHER = 'ID_NUMBER_UNVERIFIED_OTHER',
  SELFIE_DOCUMENT_MISSING_PHOTO = 'SELFIE_DOCUMENT_MISSING_PHOTO',
  SELFIE_FACE_MISMATCH = 'SELFIE_FACE_MISMATCH',
  SELFIE_MANIPULATED = 'SELFIE_MANIPULATED',
  SELFIE_UNVERIFIED_OTHER = 'SELFIE_UNVERIFIED_OTHER',
  UNDER_SUPPORTED_AGE = 'UNDER_SUPPORTED_AGE'
}

export enum UserVerificationJourney {
  MY_ACCOUNT = 'MY_ACCOUNT',
  PAYGO_ADVERT_PLACEMENT = 'PAYGO_ADVERT_PLACEMENT'
}

export enum UserVerificationStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  NOT_REQUIRED = 'NOT_REQUIRED',
  PROCESSING = 'PROCESSING',
  REQUIRED = 'REQUIRED',
  RETRY_ALLOWED = 'RETRY_ALLOWED',
  RETRY_LIMIT_EXHAUSTED = 'RETRY_LIMIT_EXHAUSTED',
  VERIFICATION_FAILED = 'VERIFICATION_FAILED',
  VERIFIED = 'VERIFIED'
}

export type VanaramaAddressHistoryInputObject = {
  readonly addresses?: InputMaybe<ReadonlyArray<InputMaybe<VanaramaAddressInputObject>>>;
  readonly partyId: Scalars['ID']['input'];
};

export type VanaramaAddressInputObject = {
  readonly buildingName?: InputMaybe<Scalars['String']['input']>;
  readonly buildingNumber?: InputMaybe<Scalars['String']['input']>;
  readonly city?: InputMaybe<Scalars['String']['input']>;
  readonly country?: InputMaybe<Scalars['String']['input']>;
  readonly county?: InputMaybe<Scalars['String']['input']>;
  readonly endedOn?: InputMaybe<Scalars['Date']['input']>;
  readonly kind?: InputMaybe<Scalars['String']['input']>;
  readonly lineOne?: InputMaybe<Scalars['String']['input']>;
  readonly lineThree?: InputMaybe<Scalars['String']['input']>;
  readonly lineTwo?: InputMaybe<Scalars['String']['input']>;
  readonly organisation?: InputMaybe<Scalars['String']['input']>;
  readonly postcode?: InputMaybe<Scalars['String']['input']>;
  readonly propertyStatus?: InputMaybe<Scalars['String']['input']>;
  readonly serviceId?: InputMaybe<Scalars['ID']['input']>;
  readonly startedOn?: InputMaybe<Scalars['Date']['input']>;
  readonly subBuilding?: InputMaybe<Scalars['String']['input']>;
  readonly uuid?: InputMaybe<Scalars['ID']['input']>;
};

export enum VanaramaAuthServiceEnum {
  AUTH0 = 'AUTH0',
  COGNITO = 'COGNITO',
  GRID = 'GRID',
  auth0 = 'auth0',
  cognito = 'cognito',
  grid = 'grid'
}

export type VanaramaBankAccountInputObject = {
  readonly accountName?: InputMaybe<Scalars['String']['input']>;
  readonly accountNumber?: InputMaybe<Scalars['String']['input']>;
  readonly bankName?: InputMaybe<Scalars['String']['input']>;
  /**  Used in V2  */
  readonly canAffordMonthlyRentals?: InputMaybe<Scalars['Boolean']['input']>;
  readonly creditHistoryCheckConsent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly joinedAt?: InputMaybe<Scalars['Date']['input']>;
  readonly joinedAtMonth?: InputMaybe<Scalars['String']['input']>;
  readonly joinedAtYear?: InputMaybe<Scalars['String']['input']>;
  readonly partyId?: InputMaybe<Scalars['ID']['input']>;
  readonly personalInfoConsent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly sortCode?: InputMaybe<Scalars['String']['input']>;
  readonly uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type VanaramaCompanyAssociateInputObject = {
  readonly addresses?: InputMaybe<ReadonlyArray<VanaramaAddressInputObject>>;
  readonly businessShare?: InputMaybe<Scalars['Float']['input']>;
  readonly countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  readonly dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  readonly emailAddress?: InputMaybe<VanaramaEmailAddressInputObject>;
  readonly emailConsent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly firstName: Scalars['String']['input'];
  readonly gender?: InputMaybe<Scalars['String']['input']>;
  readonly incomeAndExpense?: InputMaybe<VanaramaIncomeAndExpenseInputObject>;
  readonly lastName: Scalars['String']['input'];
  readonly maritalStatus?: InputMaybe<Scalars['String']['input']>;
  readonly middleName?: InputMaybe<Scalars['String']['input']>;
  readonly nationality?: InputMaybe<Scalars['String']['input']>;
  readonly noOfAdultsInHousehold?: InputMaybe<Scalars['String']['input']>;
  readonly noOfDependants?: InputMaybe<Scalars['String']['input']>;
  readonly occupation?: InputMaybe<Scalars['String']['input']>;
  readonly profilingConsent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly role?: InputMaybe<VanaramaRoleInputObject>;
  readonly smsConsent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly termsAndConditions?: InputMaybe<Scalars['Boolean']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type VanaramaCompanyDirectorInputObject = {
  readonly addresses?: InputMaybe<ReadonlyArray<VanaramaAddressInputObject>>;
  readonly associates?: InputMaybe<ReadonlyArray<VanaramaCompanyAssociateInputObject>>;
  readonly bankAccount?: InputMaybe<VanaramaBankAccountInputObject>;
  readonly emailAddress?: InputMaybe<VanaramaEmailAddressInputObject>;
  readonly person?: InputMaybe<VanaramaPersonInputObject>;
  readonly telephoneNumbers?: InputMaybe<ReadonlyArray<VanaramaTelephoneNumberInputObject>>;
  readonly turnoverPercentageOutsideUk?: InputMaybe<ReadonlyArray<VanaramaTurnoverPercentageOutsideUkInputObject>>;
  readonly uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type VanaramaCompanyInputObject = {
  readonly about?: InputMaybe<Scalars['String']['input']>;
  readonly addresses?: InputMaybe<ReadonlyArray<VanaramaAddressHistoryInputObject>>;
  readonly annualExpenses?: InputMaybe<Scalars['String']['input']>;
  readonly annualSales?: InputMaybe<Scalars['String']['input']>;
  readonly annualTurnover?: InputMaybe<Scalars['String']['input']>;
  readonly companyNature?: InputMaybe<Scalars['String']['input']>;
  readonly companyNumber?: InputMaybe<Scalars['String']['input']>;
  readonly companyType?: InputMaybe<Scalars['String']['input']>;
  readonly currentVehicleMonthlyPayment?: InputMaybe<Scalars['Float']['input']>;
  readonly deletedAt?: InputMaybe<Scalars['Date']['input']>;
  readonly emailAddresses?: InputMaybe<ReadonlyArray<VanaramaEmailAddressInputObject>>;
  readonly leadManagerId?: InputMaybe<Scalars['ID']['input']>;
  readonly legalName?: InputMaybe<Scalars['String']['input']>;
  readonly monthlyAmountBeingReplaced?: InputMaybe<Scalars['String']['input']>;
  readonly otherCountriesOfActivity?: InputMaybe<Scalars['String']['input']>;
  readonly pictureUrl?: InputMaybe<Scalars['String']['input']>;
  readonly replaceExistingVehicleFinance?: InputMaybe<Scalars['Boolean']['input']>;
  readonly replaceVehicleFinance?: InputMaybe<Scalars['Boolean']['input']>;
  readonly sicCode?: InputMaybe<Scalars['String']['input']>;
  readonly sicIndustry?: InputMaybe<Scalars['String']['input']>;
  readonly telephoneNumber?: InputMaybe<VanaramaTelephoneNumberInputObject>;
  readonly tradesOutsideUk?: InputMaybe<Scalars['Boolean']['input']>;
  readonly tradingName?: InputMaybe<Scalars['String']['input']>;
  readonly tradingSince?: InputMaybe<Scalars['Date']['input']>;
  readonly turnoverOutsideUk?: InputMaybe<Scalars['Float']['input']>;
  readonly uuid?: InputMaybe<Scalars['ID']['input']>;
  readonly vatNumber?: InputMaybe<Scalars['String']['input']>;
  readonly vehicleRegistrationPlate?: InputMaybe<Scalars['String']['input']>;
  readonly withTradingAddress?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VanaramaCompanySoleTraderInputObject = {
  readonly addresses?: InputMaybe<ReadonlyArray<VanaramaAddressInputObject>>;
  readonly associate?: InputMaybe<VanaramaCompanyAssociateInputObject>;
  readonly bankAccount?: InputMaybe<VanaramaBankAccountInputObject>;
  readonly emailAddress?: InputMaybe<VanaramaEmailAddressInputObject>;
  readonly person?: InputMaybe<VanaramaPersonInputObject>;
  readonly telephoneNumbers?: InputMaybe<ReadonlyArray<VanaramaTelephoneNumberInputObject>>;
  readonly turnoverPercentageOutsideUk?: InputMaybe<ReadonlyArray<VanaramaTurnoverPercentageOutsideUkInputObject>>;
  readonly uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type VanaramaCreditApplicationInputObject = {
  readonly aboutDetailsV2?: InputMaybe<VanaramaPersonInputObject>;
  readonly addressesV2?: InputMaybe<ReadonlyArray<VanaramaAddressInputObject>>;
  readonly bankAccountsV2?: InputMaybe<ReadonlyArray<VanaramaBankAccountInputObject>>;
  readonly companyDetailsV2?: InputMaybe<VanaramaCompanyInputObject>;
  readonly creditApplicationType?: InputMaybe<VanaramaCreditApplicationTypeEnum>;
  readonly directorsDetailsV2?: InputMaybe<VanaramaDirectorDetailInputObject>;
  readonly employmentHistoriesV2?: InputMaybe<ReadonlyArray<VanaramaEmploymentHistoryV2InputObject>>;
  readonly financeType?: InputMaybe<Scalars['String']['input']>;
  readonly incomeAndExpensesV2?: InputMaybe<VanaramaIncomeAndExpenseInputObject>;
  readonly leadManagerProposalId?: InputMaybe<Scalars['ID']['input']>;
  readonly orderUuid: Scalars['ID']['input'];
  readonly partnersDetails?: InputMaybe<Scalars['Json']['input']>;
  readonly soleTraderDetails?: InputMaybe<Scalars['Json']['input']>;
  readonly soleTraderDetailsV2?: InputMaybe<VanaramaSoleTraderDetailInputObject>;
  readonly status?: InputMaybe<Scalars['String']['input']>;
  readonly submittedAt?: InputMaybe<Scalars['DateTime']['input']>;
  readonly trackingInfo?: InputMaybe<Scalars['Json']['input']>;
  readonly vatDetailsV2?: InputMaybe<VanaramaVatDetailInputObject>;
};

export enum VanaramaCreditApplicationTypeEnum {
  B2B_LIMITED = 'B2B_LIMITED',
  B2B_PARTNERSHIP = 'B2B_PARTNERSHIP',
  B2B_REGISTERED_PARTNERSHIP = 'B2B_REGISTERED_PARTNERSHIP',
  B2B_SOLE_TRADER = 'B2B_SOLE_TRADER',
  B2C_PERSONAL = 'B2C_PERSONAL'
}

export type VanaramaDirectorDetailInputObject = {
  readonly directors?: InputMaybe<ReadonlyArray<VanaramaPersonInputObject>>;
  readonly totalPercentage?: InputMaybe<Scalars['Float']['input']>;
};

export type VanaramaEmailAddressInputObject = {
  readonly kind?: InputMaybe<Scalars['String']['input']>;
  readonly partyId?: InputMaybe<Scalars['ID']['input']>;
  readonly primary?: InputMaybe<Scalars['Boolean']['input']>;
  readonly uuid?: InputMaybe<Scalars['ID']['input']>;
  readonly value: Scalars['String']['input'];
};

export type VanaramaEmploymentHistoryInputObject = {
  readonly employmentHistories: ReadonlyArray<VanaramaEmploymentInputObject>;
  readonly partyId: Scalars['ID']['input'];
  readonly uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type VanaramaEmploymentHistoryV2InputObject = {
  readonly companyAddressBuildingName?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressBuildingNumber?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressCity?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressCountry?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressCounty?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressLineOne?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressLineThree?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressLineTwo?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressOrganisation?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressPostcode?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressServiceId?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressSubBuilding?: InputMaybe<Scalars['String']['input']>;
  readonly companyName?: InputMaybe<Scalars['String']['input']>;
  readonly contract?: InputMaybe<Scalars['String']['input']>;
  readonly currentVehicleMonthlyPayment?: InputMaybe<Scalars['Float']['input']>;
  readonly employedSinceDate?: InputMaybe<Scalars['Date']['input']>;
  readonly employedUntilDate?: InputMaybe<Scalars['Date']['input']>;
  readonly employmentStatus?: InputMaybe<Scalars['String']['input']>;
  readonly grossAnnualIncome?: InputMaybe<Scalars['Float']['input']>;
  readonly jobTitle?: InputMaybe<Scalars['String']['input']>;
  readonly replaceVehicleFinance?: InputMaybe<Scalars['Boolean']['input']>;
  readonly uuid?: InputMaybe<Scalars['ID']['input']>;
  readonly vehicleRegistrationPlate?: InputMaybe<Scalars['String']['input']>;
  readonly workPhoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type VanaramaEmploymentInputObject = {
  readonly companyAddressBuildingName?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressBuildingNumber?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressCity?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressCountry?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressCounty?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressLineOne?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressLineThree?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressLineTwo?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressOrganisation?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressPostcode?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressServiceId?: InputMaybe<Scalars['String']['input']>;
  readonly companyAddressSubBuilding?: InputMaybe<Scalars['String']['input']>;
  readonly companyName?: InputMaybe<Scalars['String']['input']>;
  readonly contract?: InputMaybe<Scalars['String']['input']>;
  readonly employedSinceDate?: InputMaybe<Scalars['Date']['input']>;
  readonly employedUntilDate?: InputMaybe<Scalars['Date']['input']>;
  readonly employmentStatus?: InputMaybe<Scalars['String']['input']>;
  readonly grossAnnualIncome?: InputMaybe<Scalars['Float']['input']>;
  readonly jobTitle?: InputMaybe<Scalars['String']['input']>;
  readonly workPhoneNumber?: InputMaybe<Scalars['String']['input']>;
};

export type VanaramaFreeInsuranceInputObject = {
  readonly eligible?: InputMaybe<Scalars['Boolean']['input']>;
  readonly optIn?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VanaramaFullCreditCheckerInputObject = {
  readonly annualMileage?: InputMaybe<Scalars['Int']['input']>;
  readonly capId?: InputMaybe<Scalars['ID']['input']>;
  readonly creditApplicationUuid: Scalars['ID']['input'];
  readonly depositPayment: Scalars['Float']['input'];
  readonly lcvCapId?: InputMaybe<Scalars['ID']['input']>;
  readonly monthlyPayment: Scalars['Float']['input'];
  readonly orderUuid?: InputMaybe<Scalars['ID']['input']>;
  readonly partyId: Scalars['ID']['input'];
  readonly term?: InputMaybe<Scalars['Int']['input']>;
  readonly vehicleType: VanaramaVehicleTypeEnum;
};

export enum VanaramaFunderCompanyTypeEnum {
  limited = 'limited',
  partnership = 'partnership'
}

export type VanaramaFunderInputObject = {
  readonly companiesHouseDirectors: Scalars['Int']['input'];
  readonly companyType: VanaramaFunderCompanyTypeEnum;
  readonly id: Scalars['ID']['input'];
  readonly percentageShares?: InputMaybe<Scalars['Int']['input']>;
  readonly userDirectors?: InputMaybe<Scalars['Int']['input']>;
};

export type VanaramaIncomeAndExpenseInputObject = {
  readonly annualIncome?: InputMaybe<Scalars['Int']['input']>;
  readonly anticipateAnnualIncomeChange?: InputMaybe<Scalars['Boolean']['input']>;
  readonly anticipateMonthlyIncomeChange?: InputMaybe<Scalars['Boolean']['input']>;
  readonly averageMonthlyIncome?: InputMaybe<Scalars['Int']['input']>;
  readonly carFinance?: InputMaybe<Scalars['Int']['input']>;
  readonly creditCardPayments?: InputMaybe<Scalars['Int']['input']>;
  readonly foodAndClothes?: InputMaybe<Scalars['Int']['input']>;
  readonly fuel?: InputMaybe<Scalars['Int']['input']>;
  readonly futureAnnualIncome?: InputMaybe<Scalars['Int']['input']>;
  readonly futureMonthlyIncome?: InputMaybe<Scalars['Int']['input']>;
  readonly householdGrossAnnualIncome?: InputMaybe<Scalars['Int']['input']>;
  readonly householdIncome?: InputMaybe<Scalars['Int']['input']>;
  readonly id?: InputMaybe<Scalars['ID']['input']>;
  readonly insurance?: InputMaybe<Scalars['Int']['input']>;
  readonly mortgageOrRent?: InputMaybe<Scalars['Int']['input']>;
  readonly otherCredit?: InputMaybe<Scalars['Int']['input']>;
  readonly partyId?: InputMaybe<Scalars['ID']['input']>;
  readonly phoneAndInternet?: InputMaybe<Scalars['Int']['input']>;
  readonly studentLoan?: InputMaybe<Scalars['Int']['input']>;
  readonly suitabilityConsent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly utilities?: InputMaybe<Scalars['Int']['input']>;
  readonly withStudentLoan?: InputMaybe<Scalars['Boolean']['input']>;
};

export enum VanaramaLeaseTypeEnum {
  BUSINESS = 'BUSINESS',
  PERSONAL = 'PERSONAL'
}

export type VanaramaLimitedCompanyInputObject = {
  readonly addresses?: InputMaybe<ReadonlyArray<VanaramaAddressInputObject>>;
  readonly associates?: InputMaybe<ReadonlyArray<VanaramaCompanyAssociateInputObject>>;
  readonly bankAccount?: InputMaybe<VanaramaBankAccountInputObject>;
  readonly companyNature?: InputMaybe<Scalars['String']['input']>;
  readonly companyNumber?: InputMaybe<Scalars['String']['input']>;
  readonly companyType?: InputMaybe<Scalars['String']['input']>;
  readonly emailAddress?: InputMaybe<VanaramaEmailAddressInputObject>;
  readonly isVatRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  readonly leadManagerId?: InputMaybe<Scalars['ID']['input']>;
  readonly legalName?: InputMaybe<Scalars['String']['input']>;
  readonly otherCountriesOfActivity?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly person?: InputMaybe<VanaramaPersonInputObject>;
  readonly previouslyTradingSoletrader?: InputMaybe<Scalars['Boolean']['input']>;
  readonly telephoneNumbers?: InputMaybe<ReadonlyArray<VanaramaTelephoneNumberInputObject>>;
  readonly tradesOutsideUk?: InputMaybe<Scalars['Boolean']['input']>;
  readonly tradingName?: InputMaybe<Scalars['String']['input']>;
  readonly tradingSince?: InputMaybe<Scalars['Date']['input']>;
  readonly turnoverOutsideUk?: InputMaybe<Scalars['Float']['input']>;
  readonly turnoverPercentageOutsideUk?: InputMaybe<ReadonlyArray<VanaramaTurnoverPercentageOutsideUkInputObject>>;
  readonly uuid?: InputMaybe<Scalars['ID']['input']>;
  readonly vatNumber?: InputMaybe<Scalars['String']['input']>;
  readonly withTradingAddress?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VanaramaLineItemInputObject = {
  readonly leadManagerQuoteId?: InputMaybe<Scalars['ID']['input']>;
  readonly orderId?: InputMaybe<Scalars['ID']['input']>;
  readonly quantity: Scalars['Int']['input'];
  readonly vehicleProduct?: InputMaybe<VanaramaVehicleProductInputObject>;
};

export type VanaramaOrderInputObject = {
  readonly additionalData?: InputMaybe<Scalars['Json']['input']>;
  readonly leaseType: VanaramaLeaseTypeEnum;
  readonly lineItems: ReadonlyArray<VanaramaLineItemInputObject>;
  readonly partyUuid?: InputMaybe<Scalars['ID']['input']>;
  readonly personUuid?: InputMaybe<Scalars['ID']['input']>;
  readonly referenceNumber?: InputMaybe<Scalars['String']['input']>;
  readonly salesChannel?: InputMaybe<Scalars['String']['input']>;
  readonly trackingInfo?: InputMaybe<Scalars['Json']['input']>;
  readonly uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type VanaramaPersonInputObject = {
  readonly about?: InputMaybe<Scalars['String']['input']>;
  readonly authServiceId?: InputMaybe<VanaramaAuthServiceEnum>;
  readonly authZeroSub?: InputMaybe<Scalars['String']['input']>;
  readonly b2c?: InputMaybe<Scalars['Boolean']['input']>;
  readonly canAffordMonthlyRentals?: InputMaybe<Scalars['Boolean']['input']>;
  readonly cognitoSub?: InputMaybe<Scalars['ID']['input']>;
  readonly company?: InputMaybe<VanaramaCompanyInputObject>;
  readonly countryOfBirth?: InputMaybe<Scalars['String']['input']>;
  readonly creditHistoryCheckConsent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly currentVehicleMonthlyPayment?: InputMaybe<Scalars['Float']['input']>;
  readonly dateOfBirth?: InputMaybe<Scalars['Date']['input']>;
  readonly deletedAt?: InputMaybe<Scalars['Date']['input']>;
  readonly disabilityRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  readonly emailAddress?: InputMaybe<VanaramaEmailAddressInputObject>;
  readonly emailAddresses?: InputMaybe<ReadonlyArray<InputMaybe<VanaramaEmailAddressInputObject>>>;
  readonly emailConsent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly firstName?: InputMaybe<Scalars['String']['input']>;
  readonly gender?: InputMaybe<Scalars['String']['input']>;
  readonly isApplicant?: InputMaybe<Scalars['Boolean']['input']>;
  readonly isDirector?: InputMaybe<Scalars['Boolean']['input']>;
  readonly jobTitle?: InputMaybe<Scalars['String']['input']>;
  readonly lastName?: InputMaybe<Scalars['String']['input']>;
  readonly leadManagerId?: InputMaybe<Scalars['ID']['input']>;
  readonly maritalStatus?: InputMaybe<Scalars['String']['input']>;
  readonly middleName?: InputMaybe<Scalars['String']['input']>;
  readonly nationality?: InputMaybe<Scalars['String']['input']>;
  readonly noOfAdultsInHousehold?: InputMaybe<Scalars['String']['input']>;
  readonly noOfDependants?: InputMaybe<Scalars['String']['input']>;
  readonly partyUuid?: InputMaybe<Scalars['ID']['input']>;
  readonly personalInfoConsent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly pictureUrl?: InputMaybe<Scalars['String']['input']>;
  readonly privacyPolicy?: InputMaybe<Scalars['Boolean']['input']>;
  readonly profilingConsent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly replaceVehicleFinance?: InputMaybe<Scalars['Boolean']['input']>;
  readonly role?: InputMaybe<VanaramaRoleInputObject>;
  readonly smsConsent?: InputMaybe<Scalars['Boolean']['input']>;
  readonly telephoneNumbers?: InputMaybe<ReadonlyArray<VanaramaTelephoneNumberInputObject>>;
  readonly termsAndConditions?: InputMaybe<Scalars['Boolean']['input']>;
  readonly title?: InputMaybe<Scalars['String']['input']>;
  readonly tradingName?: InputMaybe<Scalars['String']['input']>;
  readonly uuid?: InputMaybe<Scalars['ID']['input']>;
  readonly vatRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
  readonly vehicleRegistrationPlate?: InputMaybe<Scalars['String']['input']>;
};

export type VanaramaRoleInputObject = {
  readonly companyId?: InputMaybe<Scalars['Int']['input']>;
  readonly personId?: InputMaybe<Scalars['Int']['input']>;
  readonly position?: InputMaybe<Scalars['String']['input']>;
  readonly primaryContact?: InputMaybe<Scalars['Boolean']['input']>;
  readonly uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type VanaramaSoleTraderCompanyInputObject = {
  readonly addresses?: InputMaybe<ReadonlyArray<VanaramaAddressInputObject>>;
  readonly annualExpenses?: InputMaybe<Scalars['Float']['input']>;
  readonly annualSalesCost?: InputMaybe<Scalars['Float']['input']>;
  readonly annualTurnover?: InputMaybe<Scalars['Float']['input']>;
  readonly associate?: InputMaybe<VanaramaCompanyAssociateInputObject>;
  readonly bankAccount?: InputMaybe<VanaramaBankAccountInputObject>;
  readonly companyNature?: InputMaybe<Scalars['String']['input']>;
  readonly companyNumber?: InputMaybe<Scalars['String']['input']>;
  readonly companyType?: InputMaybe<Scalars['String']['input']>;
  readonly emailAddress?: InputMaybe<VanaramaEmailAddressInputObject>;
  readonly isVatRegistered?: InputMaybe<Scalars['Boolean']['input']>;
  readonly leadManagerId?: InputMaybe<Scalars['ID']['input']>;
  readonly legalName?: InputMaybe<Scalars['String']['input']>;
  readonly monthlyAmountBeingReplaced?: InputMaybe<Scalars['Float']['input']>;
  readonly otherCountriesOfActivity?: InputMaybe<ReadonlyArray<Scalars['String']['input']>>;
  readonly person?: InputMaybe<VanaramaPersonInputObject>;
  readonly replaceExistingVehicleFinance?: InputMaybe<Scalars['Boolean']['input']>;
  readonly telephoneNumbers?: InputMaybe<ReadonlyArray<VanaramaTelephoneNumberInputObject>>;
  readonly tradesOutsideUk?: InputMaybe<Scalars['Boolean']['input']>;
  readonly tradingName?: InputMaybe<Scalars['String']['input']>;
  readonly tradingSince?: InputMaybe<Scalars['Date']['input']>;
  readonly turnoverOutsideUk?: InputMaybe<Scalars['Float']['input']>;
  readonly turnoverPercentageOutsideUk?: InputMaybe<ReadonlyArray<VanaramaTurnoverPercentageOutsideUkInputObject>>;
  readonly uuid?: InputMaybe<Scalars['ID']['input']>;
  readonly vatNumber?: InputMaybe<Scalars['String']['input']>;
  readonly vehicleRegistrationNumber?: InputMaybe<Scalars['String']['input']>;
};

export type VanaramaSoleTraderDetailInputObject = {
  readonly associate?: InputMaybe<VanaramaPersonInputObject>;
  readonly uuid?: InputMaybe<Scalars['ID']['input']>;
};

export type VanaramaTelephoneNumberInputObject = {
  readonly countryCode?: InputMaybe<Scalars['String']['input']>;
  readonly kind?: InputMaybe<Scalars['String']['input']>;
  readonly partyId?: InputMaybe<Scalars['ID']['input']>;
  readonly primary?: InputMaybe<Scalars['Boolean']['input']>;
  readonly uuid?: InputMaybe<Scalars['ID']['input']>;
  readonly value: Scalars['String']['input'];
};

export type VanaramaTurnoverPercentageOutsideUkInputObject = {
  readonly country: Scalars['String']['input'];
  readonly percentage: Scalars['Int']['input'];
};

export type VanaramaVatDetailInputObject = {
  readonly markets?: InputMaybe<ReadonlyArray<VanaramaTurnoverPercentageOutsideUkInputObject>>;
  readonly outsideUk?: InputMaybe<Scalars['Boolean']['input']>;
  readonly vatNumber?: InputMaybe<Scalars['String']['input']>;
  readonly vatRegistered?: InputMaybe<Scalars['Boolean']['input']>;
};

export type VanaramaVehicleProductInputObject = {
  readonly annualMileage?: InputMaybe<Scalars['Int']['input']>;
  readonly colour?: InputMaybe<Scalars['String']['input']>;
  readonly depositMonths?: InputMaybe<Scalars['Int']['input']>;
  readonly depositPayment?: InputMaybe<Scalars['Float']['input']>;
  readonly derivativeCapId?: InputMaybe<Scalars['ID']['input']>;
  readonly description?: InputMaybe<Scalars['String']['input']>;
  readonly finalPayment?: InputMaybe<Scalars['Float']['input']>;
  readonly financeType?: InputMaybe<Scalars['String']['input']>;
  readonly freeInsurance?: InputMaybe<VanaramaFreeInsuranceInputObject>;
  readonly funderId?: InputMaybe<Scalars['String']['input']>;
  readonly leadTime?: InputMaybe<Scalars['String']['input']>;
  readonly maintenance?: InputMaybe<Scalars['Boolean']['input']>;
  readonly maintenancePrice?: InputMaybe<Scalars['Float']['input']>;
  readonly monthlyPayment?: InputMaybe<Scalars['Float']['input']>;
  readonly partnerSlug?: InputMaybe<Scalars['String']['input']>;
  readonly stockBatchId?: InputMaybe<Scalars['Int']['input']>;
  readonly term?: InputMaybe<Scalars['Int']['input']>;
  readonly trim?: InputMaybe<Scalars['String']['input']>;
  readonly vehicleId?: InputMaybe<Scalars['ID']['input']>;
  readonly vehicleType: VanaramaVehicleTypeEnum;
  readonly vsku?: InputMaybe<Scalars['String']['input']>;
};

export enum VanaramaVehicleTypeEnum {
  CAR = 'CAR',
  LCV = 'LCV'
}

export enum VatStatus {
  INC_VAT = 'INC_VAT',
  NO_VAT = 'NO_VAT',
  PLUS_VAT = 'PLUS_VAT'
}

export enum VehicleChannel {
  bikes = 'bikes',
  caravans = 'caravans',
  cars = 'cars',
  cycles = 'cycles',
  farm = 'farm',
  motorhomes = 'motorhomes',
  plant = 'plant',
  trucks = 'trucks',
  vans = 'vans'
}

export type VehicleCheckOrderGuaranteeInputs = {
  readonly orderId: Scalars['String']['input'];
  readonly vin: Scalars['String']['input'];
};

export type VehicleCheckOrderInput = {
  readonly advertId?: InputMaybe<Scalars['String']['input']>;
  readonly id?: InputMaybe<Scalars['String']['input']>;
  readonly reference?: InputMaybe<Scalars['String']['input']>;
  readonly registration?: InputMaybe<Scalars['String']['input']>;
};

export enum VehicleCheckOrderPaymentStatus {
  AUTH_REQUESTED = 'AUTH_REQUESTED',
  FAILED = 'FAILED',
  SUCCEEDED = 'SUCCEEDED'
}

export enum VehicleCheckOrderStatus {
  DATA_GUARANTEE_ACTIVATED = 'DATA_GUARANTEE_ACTIVATED',
  DATA_GUARANTEE_EXPIRED = 'DATA_GUARANTEE_EXPIRED',
  ORDER_CREATED = 'ORDER_CREATED',
  PAYMENT_AUTH_REQUESTED = 'PAYMENT_AUTH_REQUESTED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_REFUNDED = 'PAYMENT_REFUNDED',
  PAYMENT_SUCCEEDED = 'PAYMENT_SUCCEEDED',
  UNKNOWN = 'UNKNOWN',
  VEHICLE_CHECK_FAILED = 'VEHICLE_CHECK_FAILED',
  VEHICLE_CHECK_SUCCEEDED = 'VEHICLE_CHECK_SUCCEEDED',
  VIN_CONFIRMATION_EXPIRED = 'VIN_CONFIRMATION_EXPIRED'
}

export enum VehicleCheckStatus {
  DATA_GUARANTEE_ACTIVATED = 'DATA_GUARANTEE_ACTIVATED',
  DATA_GUARANTEE_EXPIRED = 'DATA_GUARANTEE_EXPIRED',
  ORDER_CREATED = 'ORDER_CREATED',
  PAYMENT_AUTH_REQUESTED = 'PAYMENT_AUTH_REQUESTED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  PAYMENT_SUCCEEDED = 'PAYMENT_SUCCEEDED',
  UNKNOWN = 'UNKNOWN',
  VEHICLE_CHECK_FAILED = 'VEHICLE_CHECK_FAILED',
  VEHICLE_CHECK_SUCCEEDED = 'VEHICLE_CHECK_SUCCEEDED',
  VIN_CONFIRMATION_EXPIRED = 'VIN_CONFIRMATION_EXPIRED'
}

export enum VehicleProductionStatus {
  inproduction = 'inproduction',
  preproduction = 'preproduction'
}

export enum VehicleType {
  bike = 'bike',
  car = 'car',
  caravan = 'caravan',
  crossover = 'crossover',
  cycle = 'cycle',
  farm = 'farm',
  motorhome = 'motorhome',
  plant = 'plant',
  truck = 'truck',
  van = 'van'
}
