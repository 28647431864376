import React, { useMemo } from "react";

import { Link } from "@at/design-system-elements";
import { useFederatedQuery } from "@at/sauron-platform-graphql";

import * as Styled from "./landing-page.styles";
import {
  FilterName,
  ListingType,
  SearchResultsSort,
} from "../../__generated__/fed-graphql-types";
import {
  LandingPageSearchQuery,
  LandingPageSearchQueryVariables,
} from "../../connected-components/hoc/query/__generated__/landing-page-search.fed.query";
import { landingPageSearchQuery } from "../../connected-components/hoc/query/landing-page-search.fed.query";
import { useChannel } from "../../utils/hooks/use-channel";

export const uuidProvider = {
  getUUID() {
    if (window.isSecureContext && typeof crypto.randomUUID === "function") {
      return crypto.randomUUID();
    }
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = Math.trunc(Math.random() * 16),
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      },
    );
  },
};

export const LandingPage = () => {
  const channel = useChannel();
  const searchId = useMemo(() => uuidProvider.getUUID(), []);

  const { data, loading, error } = useFederatedQuery<
    LandingPageSearchQuery,
    LandingPageSearchQueryVariables
  >(landingPageSearchQuery, {
    variables: {
      channel,
      searchId,
      filters: [
        {
          filter: FilterName.home_delivery_adverts,
          selected: ["include"],
        },
        {
          filter: FilterName.max_year_manufactured,
          selected: ["2025"],
        },
        {
          filter: FilterName.postcode,
          selected: ["wa145ag"],
        },
        {
          filter: FilterName.price_search_type,
          selected: ["total"],
        },
      ],
      sortBy: SearchResultsSort.relevance,
    },
    notifyOnNetworkStatusChange: true,
    errorPolicy: "all",
  });

  const firstAdvert = data?.searchResults?.listings.find(
    (listing) => listing.type === ListingType.NATURAL_LISTING,
  );

  const id = firstAdvert && "advertId" in firstAdvert && firstAdvert?.advertId;

  return (
    <Styled.LandingPage>
      <Styled.Heading as={"h1"}>Adverts app landing page</Styled.Heading>
      <Styled.InfoText>
        This is the Adverts App landing page which is currently only being used
        to test Burp Suite.
      </Styled.InfoText>
      <Styled.InfoText>
        Each link is dynamically generated to ensure there is a valid advert.
      </Styled.InfoText>
      {loading && <Styled.InfoText>Loading...</Styled.InfoText>}
      {error && <Styled.InfoText>Something has gone wrong...</Styled.InfoText>}
      {id && (
        <Styled.LinkList>
          <Link href={`/car-details/${id}`}>Car Advert</Link>
        </Styled.LinkList>
      )}
    </Styled.LandingPage>
  );
};
