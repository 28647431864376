import { gql } from "@at/sauron-platform-graphql";

export const landingPageSearchQuery = gql`
  query LandingPageSearchQuery(
    $filters: [FilterInput!]!
    $channel: Channel!
    $page: Int
    $sortBy: SearchResultsSort
    $listingType: [ListingType!]
    $searchId: String!
  ) {
    searchResults(
      input: {
        facets: []
        filters: $filters
        channel: $channel
        page: $page
        sortBy: $sortBy
        listingType: $listingType
        searchId: $searchId
      }
    ) {
      listings {
        ... on SearchListing {
          type
          advertId
          title
        }
        ... on GPTListing {
          type
          posId
        }
        ... on LeasingListing {
          type
          advertId
          title
        }
      }
    }
  }
`;
