import {
  ThemeSelectors,
  TypeFiesta,
  TypePhantom,
} from "@at/design-system-foundation";
import styled from "styled-components";

export const LandingPage = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  padding: ${({ theme }) => ThemeSelectors.getGroupFiveSpacing(theme)};
`;

export const Heading = styled(TypePhantom).attrs({ as: "h1" })`
  margin-bottom: ${({ theme }) => ThemeSelectors.getGroupFiveSpacing(theme)};
`;

export const InfoText = styled(TypeFiesta)`
  + p {
    margin-top: ${({ theme }) => ThemeSelectors.getGroupOneSpacing(theme)};
  }
`;

export const LinkList = styled.div`
  margin-top: ${({ theme }) => ThemeSelectors.getGroupFiveSpacing(theme)};
`;
